import { Injectable } from '@angular/core';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  meetingmode: string = "meetingmode";
  focusmode: string = "focusmode";
  defaultphotourl: string = '/assets/user.png';

  constructor() { }

  sleep(milliseconds){
    return new Promise((resolve, reject) =>{
      setTimeout(resolve, milliseconds);
    });
  }
}
