import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RecordingsService {
 

  req_headers;

  constructor(private http: HttpClient, private userservice: UserService, private toastr: ToastrService) {
    this.req_headers = new HttpHeaders({
      "content-type": "application/json",
      "accept": "application/json",
      "Authorization": `Bearer ${this.userservice.getToken()}`
    });
  }

  getRecordings(examid: string, candidateno: string = ""): Promise<any> {
    if (!candidateno) {
      return this.http.get(`${environment.serviceurl}/recording/getexamrecordingdetails?examid=${encodeURI(examid)}&token=${encodeURI(this.userservice.getToken())}`).toPromise()
        .catch(
          (error) => {
            this.toastr.error(`${error.status} ${error.statusText}`);
            this.toastr.error(`${error.error}`);
            return;
          });
    }
    else {
      return this.http.get(environment.serviceurl2 + `/recording/listvideos?examid=${encodeURI(examid)}&candidateno=${encodeURI(candidateno)}`, { headers: this.req_headers }).toPromise()
        .catch((error) => {
          this.toastr.error(`${error.status} ${error.statusText}`);
          this.toastr.error(`${error.error}`);
        });
    }
  }
  initiatedownload(examid: string, to: string): Observable<any> {
    var data = {
      to: to,
      examid: examid,
      token: `${encodeURI(this.userservice.getToken())}`
    }
    return this.http.post(`${environment.serviceurl}/recording/downloadexamvideos`, data);
  }

  getRecordingUrl(examid: string, candidateno: string, time: string): string {
    return `${environment.serviceurl2}/recording/download?examid=${encodeURI(examid)}&candidateno=${encodeURI(candidateno)}&time=${encodeURI(time)}&token=${encodeURI(this.userservice.getToken())}`;
  }

  getRecordingPlayerUrl(examid: string, candidateno: string, time: string): Observable<any> {
    return this.http.get(`${environment.serviceurl}/recording/getdownloadurl?examid=${encodeURI(examid)}&candidateno=${encodeURI(candidateno)}&time=${encodeURI(time)}&token=${encodeURI(this.userservice.getToken())}`)
  }

  checkmp4exists(examid: any, candidateno: any, time: any): Observable<any> {
    return this.http.get(`${environment.serviceurl}/recording/checkfileexists?examid=${encodeURI(examid)}&candidateno=${encodeURI(candidateno)}&time=${encodeURI(time)}&token=${encodeURI(this.userservice.getToken())}`)
  }

  downloadvideo(url:any)
  {
    return this.http.get(url);
  }
}
