import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ProcteeService {
  company!: string;
  accessCode: string;
  requestId: string;
  username: string = 'Proctor_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  name: 'Proctor';

  req_headers;

  constructor(private http: HttpClient, private userservice: UserService, private toastr: ToastrService) { 
    this.req_headers = new HttpHeaders({ 
      "content-type": "application/json", 
      "accept": "application/json" ,
      "Authorization": `Bearer ${this.userservice.getToken()}`
    });
  }

  flagProctee(accesscode: string, examid: string, candidateno: string, reason: string,proctorname:string): Promise<any>{
    return this.http.post(environment.serviceurl + `/proctees/flag`, 
                          {accesscode: accesscode, examid: examid, candidateno: candidateno, reason: reason ,proctorname:proctorname}, 
                          { headers: this.req_headers }).toPromise();
  }

  updateProcteeFlag(examid: string, candidateno: string, reviewcomments: string, unflag: boolean,unflaggedby:string) {
    return this.http.post(environment.serviceurl + `/proctees/updateflags`, 
                          { examid: examid, candidateno: candidateno, reviewcomments: reviewcomments, unflag: unflag,unflaggedby }, 
                          { headers: this.req_headers }).toPromise();
  }

  getFlags(examid: string): any{
    return this.http.get(environment.serviceurl + `/proctees/getflags?examid=${encodeURI(examid)}`, { headers: this.req_headers }).toPromise()
    .catch((error)=>{
      this.toastr.error(`${error.status} ${error.statusText}`);
    });
  }

  getReqExams():any{
    return this.http.get(`${environment.serviceurl}/requests/getrequestexamsbcompany?company=${this.userservice.getCompany()}`,{ headers: this.req_headers }).toPromise()
    .catch((error)=>{
      this.toastr.error(`${error.status} ${error.statusText}`);
    });
  }

  getPauseReasons(accesscode:any):any{
    let company= this.userservice.getCompany();
    return this.http.post(`${environment.cbtserviceurl}/exam/getpausereasons`, 
    {accesscode: accesscode, company: company},{ headers: this.req_headers }).toPromise()
    .catch((error)=>{
      this.toastr.error(`${error.status} ${error.statusText}`);
    });
  }

  pauseUnpauseProctee(accesscode,examid, candidateno,paused,reason,proctorfullname):Promise<any>{
    return this.http.post(environment.cbtserviceurl + `/exam/pauseunpausecandidate`, 
                          {accesscode: accesscode,company: this.userservice.getCompany(), examid: examid, candidateno: candidateno, paused:paused, reason: reason,proctorfullname:proctorfullname }, 
                          { headers: this.req_headers }).toPromise() 
                          .catch((error)=>{
                            this.toastr.error(`${error.status} ${error.statusText}`);
                          });
  }

  async getProctorInfo(){
    const ret = await this.http.get<any>(`${environment.serviceurl}/requests/getproctorinfo?accesscode=${encodeURI(this.accessCode)}&username=${encodeURI(this.username)}&name=${encodeURI(this.name)}`).toPromise();
    return ret;
  }

  async startProctor() {
    return await this.http.post(`${environment.serviceurl}/requests/startproctor`, {requestId: this.requestId, company: this.company}, {headers: this.req_headers}).toPromise();
  }

  destroy(){
    this.accessCode = null;
    this.requestId = null;
    this.company = null;
  }

  async getPassportUrl(): Promise<string>{
    const ret = await this.http.get<any>(`${environment.serviceurl}/requests/getpassporturl?accesscode=${this.accessCode}`).toPromise();
    return ret.passporturl;
  }
}
