import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterContentInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Proctee, AiFlag} from '../../models/Proctee'
import { Settings } from '../../models/Settings';
import { ProcteeComponent } from '../proctee/proctee.component';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ProcteeService } from '../../services/proctee.service';
import { ChatMessage } from '../../models/ChatMessage';
import { UtilService } from 'src/app/services/util.service';
import { MeetingHandlerService, NotificationMessages } from 'src/app/services/meeting-handler.service';
import { MeetingService } from 'src/app/services/meeting.service';
import { Participant } from 'src/app/models/Participant';
//import { MediasoupClient } from '../../../assets/js/mediasoup-client';
declare const io: any;
declare const $: any;
declare const MediasoupClient: any;
//declare var debug: any;
@Component({
  selector: 'app-proctees',
  templateUrl: './proctees.component.html',
  styleUrls: ['./proctees.component.css']
})
export class ProcteesComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  
  proctees = new Map<string, Proctee>();
  //pageproctees: Proctee[] = [];
  // socket;
  // mediasoupDevice;
  // mediasoupProducerTransport;
  // mediasoupConsumerTransport;
  // mediasoupProducers = [];
  settings = new Settings();
  // procteeStartIndex = 0;
  // procteeEndIndex = 0;
  //pageNo = 1;
  //pages = [];
  //mediasoupConsumers = {};
  //clientId;
  
  //mediasoupSocketIds: any = {};
  pageChangeTimeout: any;
  accesscode: string;
  subdomain: string;
  connecting = false;
  passporturl = '';
  // videoConsumersMap: Map<string, any> = new Map<string, any>();
  // audioConsumersMap: Map<string, any> = new Map<string, any>();
  handleVisibleVideoConsumersFunction;
  handleVisibleVideoConsumersInterval;
  noopinterval;
  flagreason: string = '';
  //currentchatusername = '';
  currentchatproctee: Proctee;
  chatmessage = '';
  chatClosedFunction;
  chat: any = {};//this will be an object of chat[user][]
  @ViewChild('procteescontainer') procteescontainer: ElementRef;
  @ViewChild('flagreasontextarea') flagreasontextarea: ElementRef;
  @ViewChild('chattextarea') chattextarea: ElementRef;
  @ViewChildren('proctee') procteescomponents: QueryList<ProcteeComponent>;
  pageNo: number = 1;
  ready: boolean = false;//this indicates that we have fully connected to mediasoup...if this is false, notifications of new producers, etc will be ignored...this should be ok since we will then get all current producers
  flaggedproctee: Proctee;
  flagging = false;
  procteeComponentsByProctee: any = {};//this will be a map of procteeComponentsByProctee[socketId][]
  handsRaised: number = 0;
  pausereasons:any[]=[];
  pausedproctee: Proctee;
  pausereason: any='';
  pausing: boolean;
  preconfiguredreason: any;
  proctorfullname: string;
  movingCandidatesInterval: any;
  checkNoProcteesInterval: any;
  noProcteesTimeoutMilliseconds = 30 * 60 * 1000;
  noProcteesAfterIntervalTimeout: any;
  maxProctees = 100;
  aiFlags: number  = 0;
  
  constructor(/*private ref: ChangeDetectorRef, */private router: Router, private activatedroute: ActivatedRoute, private toastr: ToastrService, private procteeservice: ProcteeService, private meetingHandlerService: MeetingHandlerService, private meetingService: MeetingService, private util: UtilService) {
    //this.procteeEndIndex = this.procteeStartIndex + this.settings.procteesperpage;
    this.handleVisibleVideoConsumersFunction = this.handleVisibleVideoConsumers.bind(this);
    this.chatClosedFunction = this.chatClosed.bind(this);
    // this.procteescomponents.changes.subscribe((x) => {
    //   console.log(x);
    // });
  }
  ngAfterContentInit(): void {
    this.handleVisibleVideoConsumersInterval = setInterval(this.handleVisibleVideoConsumersFunction, 1000);
    
  }

  get connected(){
    return this.meetingHandlerService.socket && this.meetingHandlerService.socket.connected;
  }

  // get connecting(){
  //   return this.meetingHandlerService.connecting;
  // }

  ngAfterViewInit(){
    //this.procteescontainer.nativeElement.onscroll = this.onProcteesContainerScroll.bind(this);

    $(".modal").draggable({
      handle: ".modal-header"
    });
  }

  async ngOnDestroy() {
    //disconnect from mediasoup
    if(this.handleVisibleVideoConsumersInterval){
      clearInterval(this.handleVisibleVideoConsumersInterval);
    }

    if(!this.noopinterval){
      clearInterval(this.noopinterval);
    }

    this.procteescontainer.nativeElement.onscroll = null;

    await this.meetingHandlerService.disconnect();

    this.meetingHandlerService.destroy();
    this.procteeservice.destroy();

    this.proctees = new Map<string, Proctee>();
  }

  ngOnInit(): void {

    this.proctees = new Map<string, Proctee>();
    
    $('#proctorname').modal('show');

    //this.connectMediasoupControlSocket();
  }

  //TODO: ensure an old consumer is removed before a new one is added
  // async disconnect() {
  //   // if (this.socket) {
      
  //   //   this.socket.close();
  //   //   this.socket = null;
  //   // }

  //   // //no need to transmit closing commands to the server...the server should automatically close the producers, consumers, and transports when the socket is closed
  //   // this.mediasoupProducers.forEach(async (producer) => {
  //   //   if (producer && !producer.closed) {
  //   //     //this.sendMediasoupControlRequest('closeProducer', { producerId: producer.id });
  //   //     producer.close();
  //   //   }
  //   // });

  //   // for (let procteeComponent of this.procteescomponents) {
  //   //   //if(proctee.procteeComponent){
  //   //     if (procteeComponent.videoConsumer) {
  //   //       //this.sendMediasoupControlRequest('closeConsumer', { socketId, consumerId: proctee.procteeComponent.videoConsumer.id });
  //   //       procteeComponent.videoConsumer.close();
  //   //     }
  //   //     if (procteeComponent.audioConsumer) {
  //   //       //this.sendMediasoupControlRequest('closeConsumer', { socketId, consumerId: proctee.procteeComponent.audioConsumer.id });
  //   //       procteeComponent.audioConsumer.close();
  //   //     }
  //   //   //}
  //   //   //const consumers = this.mediasoupConsumers[key];
  //   //   //consumers.forEach((consumer) => {
  //   //   //  if (consumer && !consumer.closed) {
  //   //   //    this.sendMediasoupControlRequest('closeConsumer', { socketId: this.socket.id, consumerId: consumer.id });
  //   //   //    consumer.close();
  //   //   //  }
  //   //   //});
      
  //   // }

  //   // //commented the above because mediasoup should close the producers and consumers server side once it detects that the transport has closed
  //   // //decided to leave it in, but i won't bother awaiting it

  //   // if (this.mediasoupProducerTransport && !this.mediasoupProducerTransport.closed) {
  //   //   //await this.sendMediasoupControlRequest('closeProducerTransport', { socketId: this.socket.id });
  //   //   this.mediasoupProducerTransport.close();
  //   // }

  //   // if (this.mediasoupConsumerTransport && !this.mediasoupConsumerTransport.closed) {
  //   //   //await this.sendMediasoupControlRequest('closeConsumerTransport', { socketId: this.socket.id });
  //   //   this.mediasoupConsumerTransport.close();
  //   // }

    

  //   // this.mediasoupProducerTransport = null;
  //   // this.mediasoupConsumerTransport = null;
  //   await this.meetingHandlerService.disconnect();

  //   this.proctees = new Map<string, Proctee>();
  //   // this.mediasoupProducers = [];
  //   //this.mediasoupConsumers = {};
  // }

  async accessCodeEntered(data, examid = "") {
    //this.examid = examid;
    const queryparams = this.activatedroute.snapshot.queryParams;
    //queryparams.accesscode = accesscode;

    if(!environment.production){
      this.router.navigate(['/proctees'], { queryParams: {...queryparams, accesscode: data.accesscode} });
    }
    //debugger
    this.accesscode = data.accesscode;
    this.proctorfullname= data.fullname;
    //const mythis = this;
    this.ready = false;
    this.connecting = true;
    await this.meetingHandlerService.disconnect();
    // if (this.socket) {
      
    //   this.socket.close();
    //   this.socket = null;
    // }
    //this.toastr.warning('Connecting...');

    try
    {
      //await this.addSocketIO();//.then(function () {
      //debugger;
      //await mythis.connectMediasoupControlSocket();
      //this.subscribeToMediasoupRoom();
      this.pausereasons = await this.procteeservice.getPauseReasons(this.accesscode);

      

      this.procteeservice.destroy();
      
      await this.meetingHandlerService.destroy();//we want to make sure we reset everything in the meeting service because this could be a second exam the candidate is starting

      this.procteeservice.accessCode = this.accesscode;

      this.passporturl = await this.procteeservice.getPassportUrl();

      const proctorInfo = await this.procteeservice.getProctorInfo();

      this.procteeservice.company = proctorInfo.company;
      this.procteeservice.requestId = proctorInfo.requestId;
      this.procteeservice.company = proctorInfo.company;

          //subscribe to meeting events
      this.handleMeetingEvents();//this must happen after resetting the meeting service

      
      if(!proctorInfo.started){
        await this.procteeservice.startProctor();
      }

      this.meetingHandlerService.meetingid = proctorInfo.meetingId;
      this.meetingHandlerService.jwt = proctorInfo.meetingJwt;

      await this.meetingHandlerService.init();

      //get meeting info
      //const meetinginfo = await this.meetingService.getMeeting(proctorInfo.meetingId).toPromise();

      //request server details
      await this.meetingService.requestMeetingServer(this.meetingHandlerService.meetingid, this.meetingHandlerService.jwt, this.meetingHandlerService.clientInstanceId, true).toPromise();
      const domain = await this.meetingHandlerService.getMeetingServer();

      

      //connect to server until successful
      //console.log('calling connect to meeting until successful from enterMeeting()');
      //
      this.meetingHandlerService.leaving = false;
      const successful = await Promise.race([this.meetingHandlerService.connectToMeetingUntilSuccessful(true, this.procteeservice.username), this.util.sleep(60000)]);
      //await this.meetingHandlerService.connectToMeetingUntilSuccessful(true, this.procteeservice.username);

      //this.toastr.success('Connected');

      if(successful){
        console.log('connected successfully');
      }
      else{
        this.meetingHandlerService.stopConnecting = true;
        this.meetingHandlerService.leaving = true;
        await this.meetingHandlerService.disconnect();
        this.toastr.error('Unable to connect');
      }

      //if (!await this.proctorService.producing('video') || !await this.proctorService.producing('audio')) {
    }
    catch(error){
      console.log(error);
      if(error && error.responseJSON && error.responseJSON.detail){
        this.toastr.error(error.responseJSON.detail);
      }
      else{
        this.toastr.error('Unable to connect');
      }
    }
    finally{
      this.connecting = false;
    }
    //  //mythis.proctees = mythis.getPublishingUsers();
    //}).catch(function (error) {
    //  console.log(error);
    //  alert(error);
    //});
  }

  private handleMeetingEvents() {
    this.meetingHandlerService.participantProducerRemovedSubject.subscribe(params => {
      // const { socketId, producerId, kind } = params;
      // if (kind === 'audio') {
      //   if (this.audios[socketId]) {
      //     delete this.audios[socketId][producerId];
      //     if (Object.keys(this.audios[socketId]).length == 0) {
      //       delete this.audios[socketId];
      //     }
      //   }
      // }
    });
    //this.meetingHandlerService.screenSharePublishedSubject.subscribe(() => this.resized());
    this.meetingHandlerService.participantAddedSubject.subscribe((participant: Participant) => {
      console.log('participantAddedSubject');
      if(this.meetingHandlerService.currentRoom != this.meetingHandlerService.defaultRoom){
        if(!this.proctees.has(participant.socketid)){
          const newProctee = new Proctee();
          newProctee.socketid = participant.socketid;
          newProctee.username = participant.username;
          newProctee.name = participant.name;
          newProctee.videoProducerId = participant.videoProducerId;
          newProctee.audioProducerId = participant.audioProducerId;
          newProctee.passporturl = this.passporturl + newProctee.username
          newProctee.videoAvailable = participant.videoAvailable;
          newProctee.examid = participant.data.examid;
          newProctee.handRaised = participant.handRaised;
          //newProctee.aiFlagged = participant
          this.proctees.set(participant.socketid, newProctee);
          if(this.noProcteesAfterIntervalTimeout){
            clearTimeout(this.noProcteesAfterIntervalTimeout);//we now have at least one proctee...so we can clear out this interval that should force a disconnect
            this.noProcteesAfterIntervalTimeout = undefined;
          }
        }
      }
      else{
        console.warn(`ignored participant because we are in room '${this.meetingHandlerService.currentRoom}`);
      }
    });
    this.meetingHandlerService.participantRemovedSubject.subscribe((socketid: string) => {
      this.removeProctee(socketid);
    })
    this.meetingHandlerService.meetingEndedSubject.subscribe(() => {
    });
    this.meetingHandlerService.producerTypeUnavailableSubject.subscribe(() => {
      //not needed
    });
    this.meetingHandlerService.takeAttendanceSubject.subscribe(() => {
      //not needed
    });
    this.meetingHandlerService.reconnectingSubject.subscribe(async () => {
      //not needed
    });
    this.meetingHandlerService.hideReconnectingModalSubject.subscribe(() => {
      //not needed
    });
    this.meetingHandlerService.disconnectedSubject.subscribe(async () => {
      clearInterval(this.movingCandidatesInterval);
      this.movingCandidatesInterval = undefined;
      clearInterval(this.checkNoProcteesInterval);
      this.checkNoProcteesInterval = undefined;
      this.proctees = new Map<string, Proctee>();
      this.updateAiFlags();
      if(!this.meetingHandlerService.leaving && !this.meetingHandlerService.connecting){
        this.meetingHandlerService.leaving = true;//to make double sure it doesn't try to auto reconnect
        
        //await this.disconnect();
        this.meetingHandlerService.stopConnecting = true;
        alert('Connection Lost');
        this.closeAllModals();

        //window.location.reload();
      }
      // console.log('stopping publishing in disconnect subject');
      // this.proctorService.stopPublishing(false);
    });
    // this.meetingHandlerService.toastrError.subscribe(message => {
    //   this.toastr.error(message);
    // });
    // this.meetingHandlerService.toastrWarning.subscribe(message => {
    //   this.toastr.warning(message);
    // });
    // this.meetingHandlerService.toastrSuccess.subscribe(message => {
    //   this.toastr.success(message);
    // });
    this.meetingHandlerService.notifySubject.subscribe((message) => {
      console.log('notification: ', message);
      const messageid = message.messageid;
      const data = message.data;

      switch (messageid) {
        case NotificationMessages.microphonedisabledbyhost:

          break;
        case NotificationMessages.notyetenabledtospeak:

          break;
        case NotificationMessages.webcamdisabledbyhost:

          break;
        case NotificationMessages.enabledtospeak:

          break;
        case NotificationMessages.enteredroom:

          break;
        case NotificationMessages.brokenout:

          break;
        case NotificationMessages.errorproducing:

          break;
        default:
          this.toastr.warning(`Unknown message id ${messageid}`);
      }
    });
    this.meetingHandlerService.participantHandRaisedSubject.subscribe(participant => {
      if (this.proctees.has(participant.socketid)) {
        const cProctee = this.proctees.get(participant.socketid);           
        if (!cProctee.handRaised) {
          cProctee.handRaised = true;
          this.updateRaisedHands();
          this.updateAiFlags();
        }          
      }     
    });
    this.meetingHandlerService.enteredRoomSubject.subscribe(() => {
      console.log('enteredRoomSubject');
      if(!this.movingCandidatesInterval){
        //once we have successfully connected and entered our room, periodicaly take candidates from the default room and put them in the proctor's room
        this.movingCandidatesInterval = setInterval(async () => {
          //console.log('checking for candidates in default room');
          if(this.proctees.size < this.maxProctees){//we don't want to have more than 100 candidates on the proctor's side
            if(this.meetingHandlerService.roomParticipantsMap.has(this.meetingHandlerService.defaultRoom)){
              const mainRoomParticipants = this.meetingHandlerService.roomParticipantsMap.get(this.meetingHandlerService.defaultRoom);
              for(const username of mainRoomParticipants){
                const participant = this.meetingHandlerService.getParticipantByUsername(username);//the availability of this participant is not too reliable as we are using username to find him...although this shouldn't really be a problem
                if(participant && participant.host){
                  continue;
                }
                console.log(`moving candidate '${username}' from room '${this.meetingHandlerService.defaultRoom}' to room '${this.meetingHandlerService.currentRoom}'`);
                await this.meetingHandlerService.moveParticipantsToRoom(this.meetingHandlerService.currentRoom, [username], this.meetingHandlerService.defaultRoom);
                await this.meetingHandlerService.sendCustomMessage('proctorSocketId', {proctorSocketId: this.meetingHandlerService.socket.id});

                break;
              }
            }
          }
        }, 500);
      }

      if(!this.checkNoProcteesInterval){
        this.checkNoProcteesInterval = setInterval(() => {//check every minute if there are proctees
          if(this.proctees.size == 0){//if there are no proctees, then after this.noProcteesAfterIntervalTimeout, disconnect
                                      //this.noProcteesAfterIntervalTimeout is also cleared whenever a proctee is added
            this.noProcteesAfterIntervalTimeout = setTimeout(async () => {
              console.warn('about to disconnect because we haven\'t had candidates for a while');
              if(this.proctees.size == 0){
                this.meetingHandlerService.leaving = true;
                this.meetingHandlerService.stopConnecting = true;
                await this.meetingHandlerService.disconnect();
              }
            }, this.noProcteesTimeoutMilliseconds);
          }
        }, 1 * 60 * 1000);
      }
      //this.roomChangeNotification();
    });
    this.meetingHandlerService.connectedSubject.subscribe(async (first: boolean) => {
      console.log('connectedSubject');
      //debugger;
      //this.meetingHandlerService.moveParticipantsToRoom(this.procteeservice.username, [this.procteeservice.username]);
      
    });
    this.meetingHandlerService.handDroppedSubject.subscribe((message) => {
      const socketId = message.socketId;
      const remoteId = message.remoteId; //the user who dropped the hand
      const unlocked = message.unlocked;
      const timeout = message.timeout;

    });
    // this.meetingHandlerService.remoteProducerPausedSubject.subscribe((message) => {
    // });
    // this.meetingHandlerService.remoteProducerResumedSubject.subscribe((message) => {
    // });
    this.meetingHandlerService.videoConsumerRemovedSubject.subscribe((socketId) => {
      const proctee = this.findProctee(socketId);
      if(proctee){
        this.removeProcteeProducer(socketId, proctee.videoProducerId, 'video');
      }
    });
    this.meetingHandlerService.audioConsumerRemovedSubject.subscribe((socketId) => {
      const proctee = this.findProctee(socketId);
      if(proctee){
        this.removeProcteeProducer(socketId, proctee.audioProducerId, 'audio');
      }
    });

    this.meetingHandlerService.screenShareProducerAddedSubject.subscribe(async (data: any) => {
      
    });

    this.meetingHandlerService.remoteAudioProducerAddedSubject.subscribe(async (data: { socketid: string; producerid: string; kind: string; private: boolean; }) => {
      console.log('remoteAudioProducerAddedSubject');
      //if(this.meetingHandlerService.currentRoom != this.meetingHandlerService.defaultRoom){
        await this.addProcteeProducer(data.socketid, data.producerid, data.kind);
      //}
      // const participant = this.meetingHandlerService.findParticipant(data.socketid);
      // if (participant && !participant.host) { //here, we are only interested in candidates' streams
      //   const proctee = this.findProctee(participant.socketid);
      //   if(proctee){
      //     proctee.audioProducerId = participant.audioProducerId;
      //   }
      //   else{
      //     throw new Error(`Proctee '${participant.socketid}' not found`);
      //   }
      // }
    });
    this.meetingHandlerService.remoteVideoProducerAddedSubject.subscribe(async (data: { socketid: string; producerid: string; kind: string; private: boolean; }) => {
      console.log('remoteVideoProducerAddedSubject');
      //if(this.meetingHandlerService.currentRoom != this.meetingHandlerService.defaultRoom){
        await this.addProcteeProducer(data.socketid, data.producerid, data.kind);
      //}
      // const participant = this.meetingHandlerService.findParticipant(data.socketid);
      // if (participant && !participant.host) { //here, we are only interested in candidates' streams
      //   const proctee = this.findProctee(participant.socketid);
      //   if(proctee){
      //     proctee.videoProducerId = participant.videoProducerId;
      //   }
      //   else{
      //     throw new Error(`Proctee '${participant.socketid}' not found`);
      //   }
      // }
    });
    this.meetingHandlerService.customMessageSubject.subscribe((data) => {
      switch (data.messageId) {
        case 'privateChatMessage':
          if(!this.chat[this.currentchatproctee.username]){
            this.chat[this.currentchatproctee.username] = [];
          }
          this.addChat(data.message.message, false);
          // this.chatModalComponent.addChatMessage(data.message.message, false, data.private);
          // this.examIframeComponent.examIframe.nativeElement.contentWindow.postMessage({ event: 'chatopened' }, '*');
          // $(this.chatModalComponent.chatModal.nativeElement).modal('show');
          // this.chatModalComponent.chatOpened = true;
          break;
        case 'privateChatClosed':
          //doesn't make sense to close the chat modal when the proctor closes his own...rather, the text area will be disabled
          // this.chatModalComponent.chatOpened = false;
          // this.chatModalComponent.chattextarea.nativeElement.value = '';
          break;
        case 'setCountdownTimer':
          if (this.proctees.has(data.message.remoteId)) {
            const cProctee = this.proctees.get(data.message.remoteId);           
            cProctee.timeleft = data.message.timeleft;
          }
          else{
            
          }
          break;
        case 'setProctorPauseStatus':
          if (this.proctees.has(data.message.remoteId)) {
            const cProctee = this.proctees.get(data.message.remoteId);           
            cProctee.proctorpaused = data.message.proctorpaused;
            cProctee.adminpaused = data.message.adminpaused;
            cProctee.pausereason = data.message.pausereason;
          }
          break;
        case 'noFace':
          console.log('noface');
          if(this.proctees.has(data.socketId)){
            const cProctee = this.proctees.get(data.socketId);
            this.updateAiFlags(cProctee, data.message.reason);
          }
          break;
        case 'faceChange':
          console.log('faceChange');
          if(this.proctees.has(data.socketId)){
            const cProctee = this.proctees.get(data.socketId);
            this.updateAiFlags(cProctee, data.message.reason);
          }
          break;
        case 'multipleFaces':
          console.log('multipleFaces');
          if(this.proctees.has(data.socketId)){
            const cProctee = this.proctees.get(data.socketId);
            this.updateAiFlags(cProctee, data.message.reason);
          }
          break;
        case 'candidateFaceMismatch':
          console.log('candidateFaceMismatch');
          if(this.proctees.has(data.socketId)){
            const cProctee = this.proctees.get(data.socketId);
            this.updateAiFlags(cProctee, data.message.reason);
          }
          break;
        case 'lowLight':
          console.log('lowLight');
          if(this.proctees.has(data.socketId)){
            const cProctee = this.proctees.get(data.socketId);
            this.updateAiFlags(cProctee, data.message.reason);
          }
          break;
        case 'highIntensityLight':
          console.log('highIntensityLight');
          if(this.proctees.has(data.socketId)){
            const cProctee = this.proctees.get(data.socketId);
            this.updateAiFlags(cProctee, data.message.reason);
          }
          break;
        default:

      }
    });
    this.meetingHandlerService.allParticipantsRemovedSubject.subscribe(() => {
      this.proctees = new Map<string, Proctee>();
      this.updateAiFlags();
    });
  }

  private addChat(message: any, local: boolean) {
    const newchatmessage = { message, local };
    if(!this.chat[this.currentchatproctee.username]){
      this.chat[this.currentchatproctee.username] = [];
    }
    this.chat[this.currentchatproctee.username].push(newchatmessage);

    this.scrollChatToBottom();
  }

  findProctee(socketid): Proctee {
    if (this.proctees.has(socketid)) {
      return this.proctees.get(socketid);
    }
    else {
      return null;
    }
    //for (let i = 0; i < this.proctees.size; i++) {
    //  if (this.proctees[i].socketid === socketid) {
    //    return this.proctees[i];
    //  }
    //}
   
  }

  identifyProctee(index, item: any){
    return item.key;
  }

  asIsOrder(a, b) {
    return 1;
 }

  goToPage(pageNo) {
    console.log('going to page', pageNo);
    let privateproducerexists = false;
    if (pageNo != this.pageNo) {
      this.meetingHandlerService.localMediasoupProducers.forEach((producer) => {
        if (producer.appData.recepientSocketId && !producer.closed) {//if there are any private producers, i.e the proctor is talking to any of the proctees, then we shouldn't move away
          privateproducerexists = true;
        }
      });
    }

    if(privateproducerexists){
      console.log('setting timeout to move because private producer exists')
      setTimeout(() => {
        this.goToPage(pageNo);
      }, 1000);
      return;
    }

    let listening = false;
    for(let [socketid, proctee] of this.proctees){
      if(!proctee.muted){
        listening = true;
        break;
      }
    }

    if(listening){
      console.log('setting timeout to move because proctor is currently listening to a student')//listening is automatically activated when microphone is on
      setTimeout(() => {
        this.goToPage(pageNo);
      }, 1000);
      return;
    }

    this.pageNo = pageNo;

    //const newscrolltop = this.procteescontainer.nativeElement.scrollTop += $(this.procteescontainer.nativeElement).innerHeight();
    const newscrolltop = $(this.procteescontainer.nativeElement).height() * (this.pageNo - 1);
    const nextscrolltop = $(this.procteescontainer.nativeElement).height() * (this.pageNo);
    //$(this.procteescontainer.nativeElement).scrollTop(newscrolltop);
    //this.procteescontainer.nativeElement.animate({scrollTop:newscrolltop}, '500');
    $(this.procteescontainer.nativeElement).animate({
      scrollTop: newscrolltop
    }, 500);
    // this.procteeStartIndex = this.settings.procteesperpage * (pageNo - 1);
    // this.procteeEndIndex = this.procteeStartIndex + this.settings.procteesperpage;

    // const pageprocteesarray: Proctee[] = [...this.proctees.values()].slice(this.procteeStartIndex, this.procteeEndIndex);
    // const tmppageproctees = new Map<string, Proctee>();
    // for (let i = 0; i < pageprocteesarray.length; i++) {
    //   tmppageproctees.set(pageprocteesarray[i].socketid, pageprocteesarray[i]);
    // }
    // this.pageproctees = [...tmppageproctees.values()];

    if (this.pageChangeTimeout) {
      clearTimeout(this.pageChangeTimeout);
    }
    if (this.settings.autoswitchpage) {
      this.pageChangeTimeout = setTimeout(() => {
        // if (pageNo >= this.pages.length) {
        //   pageNo = 0;
        // }
        if(nextscrolltop >= this.procteescontainer.nativeElement.scrollHeight){
          pageNo = 0;
        }
        console.log(`going to page ${pageNo + 1}`);
        this.goToPage(pageNo + 1);
        //this.ref.detectChanges();
      }, this.settings.secondsperpage * 1000);
    }
  }

  // onProcteesContainerScroll(event){
  //   //console.log('scrolling');
  // }

  //getPublishingUsers() {
  //  return [];
  //}

  settingsChanged(settings: Settings): void {
    console.log('settings changed');
    console.log(settings);
    this.settings = settings;
    // this.setPages();
    this.goToPage(this.pageNo);
  }

  async audioToggled(proctee: Proctee) {
    
    
  }

  async speakingToggled(proctee: Proctee) {
    this.dropHand(proctee);
    //const proctee: Proctee = info.proctee;
    //const speakTo = info.speakTo;
    //console.log(info);
    
  }

  flagCandidate(proctee: Proctee){
    this.flaggedproctee = proctee;
    $('#flagModal').modal('show');
    //
  }

  openChat(proctee: Proctee) {
    this.currentchatproctee = proctee;
    this.chatmessage = '';
    setTimeout(() => {
      this.scrollChatToBottom();
    });
    $('#chatModal').modal('show');
    this.chattextarea.nativeElement.focus();
    $('#chatModal').on('hidden.bs.modal', this.chatClosedFunction);
    this.dropHand(proctee);
  }

  closeChat(){
    $('#chatModal').modal('hide');
    $('#chatModal').unbind('hidden.bs.modal');
  }

  chatClosed(){
    this.meetingHandlerService.sendCustomPrivateMessage('privateChatClosed', this.currentchatproctee.socketid, {});
    //this.sendMediasoupControlRequest('privateChatClosed', {remoteusername: this.currentchatproctee.username, message: this.chatmessage});
    $('#chatModal').unbind('hidden.bs.modal');
  }

  async sendChatMessage(){
    if(!this.chatmessage){
      return;
    }
    //await this.sendMediasoupControlRequest('privateChatMessage', {remoteusername: this.currentchatproctee.username, message: this.chatmessage});
    await this.meetingHandlerService.sendCustomPrivateMessage('privateChatMessage', this.currentchatproctee.socketid, {message: this.chatmessage});
    this.addChat(this.chatmessage, true);
    this.chatmessage = '';
  }

  scrollChatToBottom(){
    $('#chatModal .modal-body').animate({ scrollTop: $('.chat-modal-chat-inner').height() }, 500);
  }

  dropHand(proctee: Proctee): void {
    if (proctee.handRaised) {
      proctee.handRaised = false;
      this.updateRaisedHands();
    }
  }

  updateRaisedHands(): void {
    let count = 0;
    for (let p of this.proctees) {
      if (p[1].handRaised) {
        count++;
      }
    }
    this.handsRaised = count;
  }

  dismissAiFlag(proctee: Proctee){    
    proctee.aiFlags = [];
    this.updateAiFlags();
  }

  private updateAiFlags(proctee: Proctee  | undefined = undefined, reason: string | undefined = undefined){    
    if(proctee){
      let cProctee = proctee.aiFlags.filter(x => x.reason == reason)[0];
      if(cProctee){
        cProctee.count++;
        cProctee.time = Date.now();
      }
      else{
        proctee.aiFlags.push({ reason: reason, count: 1, time: Date.now() });
      } 
      proctee.aiFlagsCount = 0;
      proctee.aiFlags.forEach(flag => {
        proctee.aiFlagsCount += flag.count;
      });
    }   

    let count = 0;
    for (let p of this.proctees) {
      if (p[1].aiFlags.length > 0) {
        count++;
      }
    }
    this.aiFlags = count;    
  }

  async confirmFlagCandidate(){
    try{
      this.flagging = true;
      if(this.flagreason){
        await this.procteeservice.flagProctee(this.accesscode, this.flaggedproctee.examid, this.flaggedproctee.username, this.flagreason,this.proctorfullname);
        this.flagreason = '';
        this.toastr.warning('Candidate Flagged');
        $('#flagModal').modal('hide');
        this.flaggedproctee = null;
      }
      else{
        this.toastr.error('Please specify reason');
      }
    }
    catch(error){
      this.toastr.error(error);
    }
    finally{
      this.flagging = false;
    }
  }

  pauseCandidate(proctee: Proctee){
    this.pausedproctee = proctee;
    if (!this.pausedproctee.proctorpaused) {
      $('#pauseModal').modal('show');
    }
    else {
      this.confirmPauseCandidate(false);
    }
  }

  async confirmPauseCandidate(pause:boolean=true){
    try{
      this.pausing = true;
      this.pausereason= this.pausereason? this.pausereason:this.preconfiguredreason;
      if(!this.pausereason && pause ){
        this.toastr.warning('Select Pause reason');
        return;
      }
      else{
        await this.procteeservice.pauseUnpauseProctee(this.accesscode, this.pausedproctee.examid, this.pausedproctee.username,pause, this.pausereason,this.proctorfullname);
        this.pausedproctee.proctorpaused = pause;
        this.pausereason = '';
        if(pause){
        this.toastr.warning('Candidate Paused');}
        else{
        this.toastr.warning('Candidate Un-Paused');}

        $('#pauseModal').modal('hide');
        this.pausedproctee = null;
      }
  }
    catch(error){
      this.toastr.error(error);
    }
    finally{
      this.pausing = false;
    }
  }

  async flagCandidateClicked(proctee: Proctee){

  }

  // closeProducer(producer: any) {
  //   this.sendMediasoupControlRequest('closeProducer', { producerId: producer.id });
  //   producer.close();

  // }

  

  // addSocketIO() {
  //   const mythis = this;
  //   return new Promise(function (resolve: any, reject2) {
  //    // console.log(`access code ${mythis.accesscode}`);
  //     if (mythis.accesscode) {
  //       const params = new URLSearchParams(document.location.search);
  //       let serviceurl = environment.serviceurl;//the service that this uses should always be on this port
  //       // if (!environment.production) {
  //       //   //debug = true;
  //       //   serviceurl = `//localhost:11589`;
  //       // }
  //       const domainservice = `${serviceurl}/requests/getproctorsubdomain?accesscode=${mythis.accesscode}`;
  //       const passporturlservice = `${serviceurl}/requests/getpassporturl?accesscode=${mythis.accesscode}`;

  //       const setPassportUrl = function () {
  //         $.get(passporturlservice, function (data) {
  //          // console.log('passport url: ', data);
  //           mythis.passporturl = data.passporturl;
  //         }).fail((error) => {
  //           console.log(error);
  //           if(error && error.responseJSON && error.responseJSON.detail){
  //             mythis.toastr.warning(error.responseJSON.detail);
  //           }
  //           else{
  //             mythis.toastr.warning('Unable to set passport url');
  //           }
  //           //setTimeout(setPassportUrl);
  //         });
  //       }
  //       setPassportUrl();
        

  //       $.get(domainservice, function (data) {
  //        // console.log(data);
  //         if (data.subdomain) {
  //           mythis.subdomain = data.subdomain;
  //           let script: any = document.getElementById('socketioscript');
  //           if (script) {
  //             console.log('resolving');
  //             resolve();
  //           }
  //           else {
  //             script = document.createElement('script');
  //             script.setAttribute('id', 'socketioscript');
  //             script.onload = function () {
  //               console.log('resolving');
  //               resolve();
  //             };

  //             const scriptsrc = `//${mythis.subdomain}:7070/socket.io/socket.io.js`;
  //             script.onerror = function (error) {
  //               console.log(error);
  //               console.error(`unable to load script at '${scriptsrc}'`);
  //               reject2(`unable to load script at '${scriptsrc}'. Please check your internet connection`);
  //             };
  //             script.src = scriptsrc;

  //             document.head.appendChild(script);
  //           }
  //         } else {
  //           reject2(`subdomain not found for access code '${mythis.accesscode}'`);
  //         }
  //       }).fail(function (error) {
  //         reject2(error);
  //       });
  //     } else {
  //       reject2('Access code not specified');
  //     }
  //   });
  // }

  closeAllModals(){
    $('.modal').modal('hide');
  }

  // connectMediasoupControlSocket() {
    
  //   if (this.socket) {
  //     this.socket.close();
  //     this.socket = null;
  //     //debugger;
  //     this.clientId = null;
  //   }
  //   const mythis = this;
  //   //debugger;
  //   return new Promise((resolve: any, reject) => {
  //     // mythis.socket = io.connect(`//${mythis.subdomain}:7070?`, { query: { username: `proctor_${mythis.username}`, name: 'Proctor', host: true/*, examid: this.examid*/ }, reconnection: false });
  //     // this.reconnecting = false;
  //     // let socket = mythis.socket;
      
  //     // socket.on('connect', function (evt) {
  //     //   console.log('socket.io connected()');
  //     // });
  //     // socket.on('error', function (err) {
  //     //   console.error('socket.io ERROR:', err);
  //     //   alert('Connection Lost');
  //     //   mythis.closeAllModals();
  //     //   window.location.reload();
  //     //   setTimeout(async function () {
  //     //     // if (this.accesscode) {
  //     //     //   await mythis.accessCodeEntered(mythis.accesscode);
  //     //     // }
  //     //     //alert('Websocket error.');
  //     //     //document.location.reload();
  //     //     //publishVideo();
  //     //   }, 1000);
  //     //   reject(err);
  //     // });

  //     // socket.on('disconnect', function (evt) {
  //     //   // if (!mythis.reconnecting) {
  //     //   //   console.log('socket.io disconnect:', evt);
  //     //   //   //publishVideo();
  //     //   //   //alert('Websocket disconnected');
  //     //   //   //document.location.reload();
  //     //   //   await mythis.accessCodeEntered(mythis.accesscode);
  //     //   // }
  //     //   if(!mythis.reconnecting){
  //     //     mythis.disconnect();
  //     //     alert('Connection Lost');
  //     //     mythis.closeAllModals();
  //     //     //window.location.reload();
  //     //   }
  //     // });
  //     // socket.on('welcome', function (message) {
  //     //   console.log('socket.io message:', message);
  //     //   if (socket.id !== message.id) {
  //     //     console.warn('WARN: something wrong with clientID', socket.io, message.id);
  //     //   }
  //     //   //debugger;
  //     //   mythis.clientId = message.id;
  //     //   console.log('connected to server. clientId=' + mythis.clientId);
  //     //   resolve();
  //     // });

  //     // socket.on('newPeer', async function(message){
  //     //   //debugger;
  //     //   const socketid = message.socketid;
  //     //   const currentProducersInfo: any[] = message.currentProducersInfo;
  //     //   if(mythis.ready)
  //     //   {
  //     //     mythis.addProctee(socketid, message);
  //     //     currentProducersInfo.forEach(async producerInfo => {
  //     //       await mythis.addProcteeProducer(socketid, producerInfo.producerId, producerInfo.kind);
  //     //     });
  //     //   }
  //     //   //this is where we should actually create the proctee
  //     // });

  //     // socket.on('roomChanged', async (message) => {
  //     //   //we're not likely to use this because the proctor creates his own room that proctees will be assigned to
  //     // });
      
  //     // socket.on('newProducer', async function (message) {
  //     //   console.log('socket.io newProducer:', message);
  //     //   const remoteId = message.socketId;
  //     //   const prdId = message.producerId;
  //     //   const kind = message.kind;

  //     //   if(mythis.ready){
  //     //     await mythis.addProcteeProducer(remoteId, prdId, kind);
  //     //   }
        

  //     // });

  //     // socket.on('producerClosed', async function (message) {
  //     //   console.log('socket.io producerClosed:', message);
  //     //   //const localId = message.localId;
  //     //   const remoteId = message.remoteId;
  //     //   const kind = message.kind;
  //     //   //const consumerId = message.consumerId;
  //     //   const producerId = message.producerId;
  //     //   console.log('--try removeConsumer remoteId=%s, producerId=%s, track=%s', remoteId, producerId, kind);
  //     //   //mythis.removeMediasoupConsumer(remoteId, consumerId);
  //     //   //mythis.removeRemoteVideo(remoteId);
  //     //   //await mythis.removeProcteeProducer(remoteId, )
  //     //   mythis.removeProcteeProducer(remoteId, producerId, kind);
  //     // });

  //     // socket.on('producerPaused', async (message) => {
  //     //   //i don't think we'll use this because nobody will be pausing the proctor's streams
  //     // });

  //     // socket.on('producerResumed', async (message) => {
  //     //   if(socket.id == message.remoteId){
  //     //     let producerId = message.producerId;
          
  //     //   }
  //     // });

  //     // socket.on('peerDisconnected', async function(message){//this is called when a peer disconnects
  //     //   console.log('socket.io peerDisconnected', message);
  //     //   mythis.removeProctee(message.remoteId);
  //     // });

  //     // socket.on('peerLeft', async function(message){//this is called when a peer leaves the room
  //     //   console.log('peerLeft', message);
  //     //   if(message.remoteId == socket.id){//if i am the one leaving a room, remove all participants
  //     //     //remove all producers
  //     //     //await mythis.removeAllParticipants(mythis);
  //     //   }
  //     //   else{
  //     //     mythis.removeProctee(message.remoteId);
  //     //   }
  //     // });

  //     // socket.on('peerEnteredRoom', (message) => {
  //     //   //for hosts only
  //     //   // const {room, socketid, username} = message;
  //     //   // this.peerEnteredRoom(room, username);
  //     // });

  //     // socket.on('peerLeftRoom', (message) => {
  //     //   //for hosts only
  //     //   // const {room, socketid, username} = message;
  //     //   // this.peerLeftRoom(room, username);
  //     // });

  //     // socket.on('setCountdownTimer', (message) => {
  //     //   if (this.proctees.has(message.username)) {
  //     //     const cProctee = this.proctees.get(message.username);           
  //     //     cProctee.timeleft = message.timeleft;
  //     //   }
  //     //   else{
          
  //     //   }
  //     // });

  //     // socket.on('setProctorPauseStatus', (message) => {
  //     //  // console.log("pause message"+ JSON.stringify(message))
  //     //   if (this.proctees.has(message.username)) {
  //     //     const cProctee = this.proctees.get(message.username);           
  //     //     cProctee.proctorpaused = message.proctorpaused;
  //     //     cProctee.adminpaused = message.adminpaused;
  //     //     cProctee.pausereason = message.pausereason;
  //     //   }
      
  //     // });

  //     // socket.on('privateChatMessage', (message) => {
  //     //   if(!this.chat[this.currentchatproctee.username]){
  //     //     this.chat[this.currentchatproctee.username] = [];
  //     //   }
  //     //   const newchatmessage = new ChatMessage();
  //     //   newchatmessage.message = message.message;
  //     //   //newchatmessage.local = message.username == `proctor_${mythis.username}`;
  //     //   mythis.chat[this.currentchatproctee.username].push(newchatmessage);

  //     //   mythis.scrollChatToBottom();
  //     // });

  //     // socket.on('raiseHand', (message) => {
  //     //   if (this.proctees.has(message.username)) {
  //     //     const cProctee = this.proctees.get(message.username);           
  //     //     if (!cProctee.handRaised) {
  //     //       cProctee.handRaised = true;
  //     //       this.updateRaisedHands();
  //     //     }          
  //     //   }       
  //     // });
  
  //     // if(!this.noopinterval){
  //     //   this.noopinterval = setInterval(() => {
  //     //     mythis.sendMediasoupControlRequest('no-op', {});//to prevent timing out
  //     //   }, 1000);
  //     // }
  //   });
  // }

  removeProctee(socketId){
    const proctee = this.proctees.get(socketId);
    if (proctee) {
      proctee.removed = true;
      if (proctee.videoProducerId){
        this.removeProcteeProducer(socketId, proctee.videoProducerId, 'video');
      }
      if(proctee.audioProducerId){
        this.removeProcteeProducer(socketId, proctee.audioProducerId, 'audio');
      }
      const mythis = this;
      proctee.disconnected = true;
      setTimeout(() => {
        mythis.proctees.delete(socketId);//only remove proctee after 30 seconds...this is to allow for flagging after the user has disconnected
        this.updateRaisedHands();
        this.updateAiFlags();
      }, 30 * 1000);
      
      //this.setPages();
    }
  }

  getProcteeComponent(socketId): ProcteeComponent{
    if(this.proctees.has(socketId)){
      const proctee = this.proctees.get(socketId);
      if(proctee && proctee.procteeComponent){
        return proctee.procteeComponent;
      }
    }

    // console.warn('proctee not found using proctee.procteeComponent');
    // //this loop is in the unlikely event the procteeComponent is not found using the method above
    // for(const procteeComponent of this.procteescomponents){
    //   if(procteeComponent.proctee && procteeComponent.proctee.socketid == socketId){
    //     return procteeComponent;
    //   }
    // }

    // console.warn('proctee still not found');
    return null;

  }

  removeProcteeProducer(socketId, producerId, kind) {
    const procteeComponent = this.getProcteeComponent(socketId);

    const proctee = this.proctees.get(socketId);
    if (proctee) {
      if (proctee.videoProducerId === producerId && procteeComponent && procteeComponent.videoConsumer) {
        proctee.videoProducerId = null;
        procteeComponent.videoConsumer = null;
      }
      else if (proctee.audioProducerId === producerId && procteeComponent && procteeComponent.audioConsumer) {
        proctee.audioProducerId = null;
        procteeComponent.audioConsumer = null;
      }
      if(this.currentchatproctee == proctee){
        this.closeChat();
      }
    }
  }

  async addProcteeProducer(socketId, producerId, kind) {
    
    let proctee = this.findProctee(socketId);
    //if (!proctee) {
      
      
    //}
    //debugger;
    if (kind === 'video') {
      proctee.videoProducerId = producerId;
    } else {
      proctee.audioProducerId = producerId;
    }
    proctee.connected = true;
    proctee.disconnected = false;
    //this.ref.detectChanges();
    const procteeComponent = this.getProcteeComponent(socketId);
    if(procteeComponent){
      procteeComponent.setStream(kind);
    }
  }

  // sendMediasoupControlRequest(type, data) {
  //   const socket = this.socket;
  //   return new Promise((resolve, reject) => {
  //     socket.emit(type, data, (err, response) => {
  //       if (!err) {
  //         // Success response, so pass the mediasoup response to the local Room.
  //         resolve(response);
  //       } else {
  //         reject(err);
  //       }
  //     });
  //   });
  // }

  // async loadMediasoupDevice(routerRtpCapabilities) {
  //   try {
  //     this.mediasoupDevice = new MediasoupClient.Device();
  //   } catch (error) {
  //     if (error.name === 'UnsupportedError') {
  //       console.error('browser not supported');
  //     }
  //   }
  //   await this.mediasoupDevice.load({ routerRtpCapabilities });
  // }

  // isMediasoupControlSocketConnected() {
  //   if (this.socket) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  // async publishToMediasoupRoom(tracks: MediaStreamTrack[], recepientSocketId?: string) {
    
  //   for (let i = 0; i < tracks.length; i++) {
  //     const trackParams = { track: tracks[i], appData: { recepientSocketId }, zeroRtpOnPause: true };
  //     const producer = await this.mediasoupProducerTransport.produce(trackParams);
  //     this.mediasoupProducers.push(producer);
  //     console.log('Producer id: ' + producer.id);
  //   }
  // }

  // async closeProducerTransport() {
  //   try {
  //     await this.sendMediasoupControlRequest('closeProducerTransport', { socketId: this.socket.id });
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   this.mediasoupProducerTransport.close();
  //   this.mediasoupProducerTransport = null;
  // }

  // async subscribeToMediasoupRoom() {
  //   //debugger;
  //   //let mediasoupConsumerTransport = this.mediasoupConsumerTransport;
  //   if (!this.isMediasoupControlSocketConnected()) {
  //     //debugger;
  //     await this.connectMediasoupControlSocket().catch(err => {
  //       console.error(err);
  //       return;
  //     });

  //     // --- get capabilities --
  //     const data = await this.sendMediasoupControlRequest('getRouterRtpCapabilities', {});
  //     console.log('getRouterRtpCapabilities:', data);
  //     await this.loadMediasoupDevice(data);
  //   }


  //   // --- prepare transport ---
  //   console.log('--- createConsumerTransport --');
  //   if (!this.mediasoupConsumerTransport || this.mediasoupConsumerTransport.closed) {
  //     const params = await this.sendMediasoupControlRequest('createConsumerTransport', {});
  //     console.log('transport params:', params);
  //     this.mediasoupConsumerTransport = this.mediasoupDevice.createRecvTransport(params);
  //     console.log('createConsumerTransport:', this.mediasoupConsumerTransport);

  //     // --- join & start publish --
  //     this.mediasoupConsumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
  //       console.log('--consumer trasnport connect');
  //       this.sendMediasoupControlRequest('connectConsumerTransport', { dtlsParameters: dtlsParameters })
  //         .then(callback)
  //         .catch(errback);
  //     });

  //     this.mediasoupConsumerTransport.on('connectionstatechange', async (state) => {
  //       switch (state) {
  //         case 'connecting':
  //           console.log('consumer transport connecting...');
  //           break;
  //         case 'connected':
  //           console.log('consumer transport connected');
  //           //consumeCurrentProducers(clientId);
  //           break;
  //         case 'disconnected':
  //           console.log('consumer transport disconnected');
  //           //alert('Connection Lost');
  //           //await this.accessCodeEntered(this.accesscode);
  //           break;
  //         case 'failed':
  //           console.log('consumer transport failed');
  //           alert('Unable to connect');
  //           break;
  //         default:
  //           console.log(`unexpected consumer transport state: ${state}`);
  //           break;
  //       }
  //     });

  //     this.ready = true;
  //     this.sendMediasoupControlRequest('ready', {});
  //     await this.consumeCurrentMediasoupProducers(this.clientId);

  //     this.toastr.success('Connected...');
  //   }

  //   if (this.mediasoupProducerTransport) {
  //     try {
  //       this.mediasoupProducerTransport.close();
  //     }
  //     catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   // --- get transport info ---
  //   console.log('--- createProducerTransport --');
  //   const params = await this.sendMediasoupControlRequest('createProducerTransport', {});
  //   console.log('transport params:', params);
  //   this.mediasoupProducerTransport = this.mediasoupDevice.createSendTransport(params);
  //   console.log('createSendTransport:', this.mediasoupProducerTransport);

  //   // --- join & start publish --
  //   this.mediasoupProducerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
  //     console.log('--trasnport connect');
  //     this.sendMediasoupControlRequest('connectProducerTransport', { dtlsParameters: dtlsParameters })
  //       .then(callback)
  //       .catch(errback);
  //   });

  //   this.mediasoupProducerTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
  //     //debugger;
  //     console.log('--trasnport produce');
  //     try {
  //       const recepientSocketId = appData.recepientSocketId;
  //       const { id }: any = await this.sendMediasoupControlRequest('produce', {
  //         transportId: this.mediasoupProducerTransport.id,
  //         kind,
  //         rtpParameters,
  //         appData,
  //         recepientSocketId
  //       });
  //       callback({ id });
  //       console.log('--produce requested, then subscribe ---');

  //     } catch (err) {
  //       errback(err);
  //     }
  //   });

  //   this.mediasoupProducerTransport.on('connectionstatechange', async (state) => {
  //     let stream;
  //     switch (state) {
  //       case 'connecting':
  //         console.log('producer transport connecting...');
  //         break;

  //       case 'connected':
  //         console.log('producer transport connected');
  //         break;
  //       case 'disconnected':
  //         console.log('producder transport disconnected');
  //         //await this.closeProducerTransport();
  //         //debugger;
  //         //if (started) {
  //         //  mediasoupProducerTransport.close();
  //         //  stream = await getVideoStream();
  //         //  publishToMediasoupRoom(stream);
  //         //}
  //         break;
  //       case 'failed':
  //         console.log('consumer transport failed');
  //         this.mediasoupProducerTransport.close();
  //         alert('Unable to stream. Please refresh the page to try again');
  //         break;
  //       default:
  //         console.log(`unexpected consumer transport state: ${state}`);
  //         break;
  //     }
  //   });
  //   // this.ready = true;
  //   // this.sendMediasoupControlRequest('ready', {});
  // }

  // async consumeCurrentMediasoupProducers(clientId) {
  //   console.log('current client id: ', clientId);
  //   console.log('-- try consumeAll() --');
  //   const remoteInfo: any = await this.sendMediasoupControlRequest('getCurrentProducers', { localId: clientId })
  //     .catch(err => {
  //       console.error('getCurrentProducers ERROR:', err);
  //       return;
  //     });
  //   //console.log('remoteInfo.producerIds:', remoteInfo.producerIds);
  //   console.log('remoteInfo.remoteVideoIds:', remoteInfo.remoteVideoIds);
  //   console.log('remoteInfo.remoteAudioIds:', remoteInfo.remoteAudioIds);
  //   this.addCurrentProducers(this.mediasoupConsumerTransport, remoteInfo.remoteVideoIds, remoteInfo.remoteAudioIds, remoteInfo.ids);
  // }

  // addCurrentProducers(transport, remoteVideoIds, remotAudioIds, ids) {
  //   console.log('remote VideoIds: ', remoteVideoIds);
  //   console.log('----- consumeAll() -----')

  //   this.mediasoupSocketIds = ids;

  //   //debugger;
  //   for(let socketid in this.mediasoupSocketIds){
  //     const details = this.mediasoupSocketIds[socketid];

  //     if(!details.username.startsWith('proctor_'))
  //     {
  //       this.addProctee(socketid, details);
  //     }
  //   }


  //   remoteVideoIds.forEach(remoteVideo => {
  //     //this.consumeAddMediasoupTrack(transport, remoteVideo.socketId, remoteVideo.producerId, 'video');
  //     this.addProcteeProducer(remoteVideo.socketId, remoteVideo.producerId, 'video');
  //   });
  //   remotAudioIds.forEach(remoteVideo => {
  //     //this.consumeAddMediasoupTrack(transport, remoteVideo.socketId, remoteVideo.producerId, 'audio');
  //     this.addProcteeProducer(remoteVideo.socketId, remoteVideo.producerId, 'audio');
  //   });
  // }

  // private addProctee(socketid: string, details: any) {
  //   const proctee = new Proctee();
  //   proctee.socketid = socketid;
  //   proctee.muted = true;

  //   proctee.username = details.username;
  //   proctee.name = details.name;
  //   proctee.examid = details.examid;
  //   proctee.passporturl = this.passporturl + proctee.username;
  //   proctee.removed = false;
  //   this.proctees.set(socketid, proctee);
  //   this.updateRaisedHands();
  // }

  // async createConsumer(socketid: string, producerId: string, kind: string) {
  //   console.log(`creating '${kind}' consumer for '${socketid}', '${producerId}'`);
  //   const { rtpCapabilities } = this.mediasoupDevice;
  //   const data: any = await this.sendMediasoupControlRequest('consumeAdd', { rtpCapabilities: rtpCapabilities, remoteId: socketid, prdId: producerId, kind: kind, paused: true })
  //     .catch(err => {
  //       console.error('consumeAddMediasoupTrack ERROR:', err);
  //     });
  //   const {
  //     //producerIdLocal,
  //     id,
  //     kindLocal,
  //     rtpParameters,
  //   }: any = data;

  //   if (producerId && (producerId !== data.producerId)) {
  //     console.warn('producerID NOT MATCH');
  //   }

  //   let codecOptions = {};
  //   const consumer = await this.mediasoupConsumerTransport.consume({
  //     id,
  //     producerId,
  //     kind,
  //     rtpParameters,
  //     codecOptions,
  //   });
  //   consumer.pause();//since we have started the consumer paused, we have to pause it locally
  //   return {consumer};
  // }

  // async closeConsumer(socketId: string, consumer: any, awaitServerPause?: boolean) {
  //   if (consumer) {
  //     if (consumer.closed) {
  //       //debugger;
  //     }
  //     if(this.socket && !this.socket.closed){
  //       const promise = this.sendMediasoupControlRequest('closeConsumer', { remoteId: socketId, kind: consumer.kind, consumerId: consumer.id });
  //       if (awaitServerPause) {
  //         await promise;
  //       }
  //     }
  //     consumer.close();
  //   }
  // }

  // async pauseConsumer(socketid: string, consumer: any, awaitServerPause?: boolean) {
  //   if (consumer) {
  //     if (consumer.closed) {
  //       debugger;
  //     }
  //     console.log(`pausing consumer `, { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id });
  //     const promise = this.sendMediasoupControlRequest('pauseConsumer', { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id });
  //     if (awaitServerPause) {
  //       await promise;
  //     }
  //     consumer.pause();
  //   }
  // }

  // async resumeConsumer(socketid: string, consumer: any, awaitServerPause?: boolean) {
  //   if (consumer) {
  //     if (consumer.closed) {
  //       debugger;
  //     }
  //     else {
  //       console.log(`resuming consumer `, { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id });
  //       const promise = this.sendMediasoupControlRequest('resumeConsumer', { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id });
  //       if (awaitServerPause) {
  //         await promise;
  //       }
  //       consumer.resume();
  //     }
  //   }
  // }

  //async consumeAddMediasoupTrack(transport, remoteSocketId, prdId, trackKind) {
  //  console.log('--start of consumeAdd -- producerId=%s kind=%s', prdId, trackKind);
  //  const { rtpCapabilities } = this.mediasoupDevice;
  //  //const data = await socket.request('consume', { rtpCapabilities });
  //  const data = await this.sendMediasoupControlRequest('consumeAdd', { rtpCapabilities: rtpCapabilities, remoteId: remoteSocketId, prdId: prdId, kind: trackKind, paused: true })
  //    .catch(err => {
  //      console.error('consumeAddMediasoupTrack ERROR:', err);
  //    });
  //  const {
  //    producerId,
  //    id,
  //    kind,
  //    rtpParameters,
  //  }: any = data;
  //  if (prdId && (prdId !== producerId)) {
  //    console.warn('producerID NOT MATCH');
  //  }

  //  let codecOptions = {};
  //  const consumer = await transport.consume({
  //    id,
  //    producerId,
  //    kind,
  //    rtpParameters,
  //    codecOptions,
  //  });
  //  //const stream = new MediaStream();
  //  //stream.addTrack(consumer.track);

  //  //const userdetails = await this.getMediasoupSocketIdUserDetails(id);
  //  //debugger;
  //  //if (userdetails && userdetails.username && userdetails.username.startsWith('proctor_')) {
  //  //  return;
  //  //}

  //  this.addRemoteMediasoupTrack(remoteSocketId, consumer.track);
  //  this.addMediasoupConsumer(remoteSocketId, consumer);
  //  consumer.remoteId = remoteSocketId;

  //  const mythis = this;
  //  consumer.on("transportclose", () => {
  //    console.log('--consumer transport closed. remoteId=' + consumer.remoteId);
  //    //consumer.close();
  //    //removeMediasoupConsumer(remoteId);
  //    //removeRemoteVideo(consumer.remoteId);
  //  });
  //  consumer.on("producerclose", () => {
  //    console.log('--consumer producer closed. remoteId=' + consumer.remoteId);
  //    consumer.close();
  //    mythis.removeMediasoupConsumer(consumer.remoteId, consumer.id);
  //    mythis.removeRemoteVideo(consumer.remoteId);
  //  });
  //  consumer.on('trackended', () => {
  //    console.log('--consumer trackended. remoteId=' + consumer.remoteId);
  //    //consumer.close();
  //    //removeMediasoupConsumer(remoteId);
  //    //removeRemoteVideo(consumer.remoteId);
  //  });

  //  console.log('--end of consumeAddMediasoupTrack');
  //}

  //addMediasoupConsumer(id, consumer) {

  //  if (!this.mediasoupConsumers[id]) {
  //    this.mediasoupConsumers[id] = [];
  //  }
  //  this.mediasoupConsumers[id].push(consumer);
  //}

  //removeMediasoupConsumer(id, consumerId) {
  //  if (this.mediasoupConsumers[id]) {
  //    for (let i = 0; i < this.mediasoupConsumers[id].length; i++) {
  //      if (this.mediasoupConsumers[id][i].id === consumerId) {
  //        try {
  //          this.mediasoupConsumers[id][id].close();
  //        } catch{ }
  //        this.mediasoupConsumers[id].splice(id, 1);
  //      }
  //    }
  //  }
  //}

  // procteeDestroyed(proctee: Proctee) {
  //   //proctee.muted = true;
  //   //proctee.speakingTo = false;
  //   //this.pauseConsumers(proctee);
  // }

  // streamSettingRequired(proctee: Proctee) {
  //   //if (proctee.videoConsumer) {
  //   //  //this.setVideoTrack(proctee, proctee.videoTrack);
  //   //}
  //   //if (proctee.audioConsumer) {
  //   //  //this.setAudioTrack(proctee, proctee.audioTrack);
  //   //}

  //   //this.resumeConsumers(proctee, false, proctee.muted ? 'video' : undefined);//undefined will resume all streams
  // }

  //async closeConsumers(proctee: Proctee, awaitServerPause?: boolean, kind?: string) {
  //  const socketConsumers = [proctee.videoConsumer, proctee.audioConsumer];// this.mediasoupConsumers[proctee.socketid];
  //  if (socketConsumers) {
  //    socketConsumers.forEach(async (consumer) => {
  //      if (consumer && (consumer.kind === kind || !kind)) {
  //        if (consumer.closed) {
  //          debugger;
  //        }
  //        const promise = this.sendMediasoupControlRequest('closeConsumer', { socketId: proctee.socketid, kind: consumer.kind, consumerId: consumer.id });
  //        if (awaitServerPause) {
  //          await promise;
  //        }
  //        consumer.close();
  //      }
  //    });
  //  }
  //}

  // async pauseConsumers(proctee: Proctee, awaitServerPause?: boolean, kind?: string) {
  //   if(proctee.procteeComponent){
  //     const socketConsumers = [proctee.procteeComponent.videoConsumer, proctee.procteeComponent.audioConsumer];
  //     if (socketConsumers) {
  //       socketConsumers.forEach(async (consumer) => {
  //         if (consumer && (consumer.kind === kind || !kind)) {
  //           if (consumer.closed) {
  //             debugger;
  //           }
  //           const promise = this.sendMediasoupControlRequest('pauseConsumer', { remoteId: proctee.socketid, kind: consumer.kind, consumerId: consumer.id });
  //           if (awaitServerPause) {
  //             await promise;
  //           }
  //           consumer.pause();
  //         }
  //       });
  //     }
  //   }
  // }

  // async resumeConsumers(proctee: Proctee, awaitServerPause?: boolean, kind?: string) {
  //   if(proctee.procteeComponent){
  //     [proctee.procteeComponent.videoConsumer, proctee.procteeComponent.audioConsumer].forEach(async (consumer) => {
  //       if (consumer && (consumer.kind === kind || !kind)) {
  //         const promise = this.sendMediasoupControlRequest('resumeConsumer', { remoteId: proctee.socketid, kind: consumer.kind, consumerId: consumer.id });
  //         if (awaitServerPause) {
  //           await promise;
  //         }
  //         consumer.resume();
  //       }
  //     });
  //   }
  // }

  handleVisibleVideoConsumers(){
    for(let procteeComponent of this.procteescomponents){
      if(procteeComponent && procteeComponent.video){
        const video: any = procteeComponent.video;
        const scrolledintoview = procteeComponent.scrolledIntoView();
        if(scrolledintoview){
          procteeComponent.proctee.visible = true;
          if(procteeComponent.videoConsumer && (procteeComponent.videoConsumer.paused || video.nativeElement.paused || video.nativeElement.currentTime == 0) && video.nativeElement.srcObject){
            this.meetingHandlerService.resumeConsumer(procteeComponent.proctee.socketid, procteeComponent.videoConsumer, true);
          }
          else if(!procteeComponent.videoConsumer || procteeComponent.videoConsumer.closed || !video.nativeElement.srcObject){
            procteeComponent.setStream('video');
            this.meetingHandlerService.resumeConsumer(procteeComponent.proctee.socketid, procteeComponent.videoConsumer, true);
          }
        }
        else{
          procteeComponent.proctee.visible = false;
          if(procteeComponent.videoConsumer && !procteeComponent.videoConsumer.paused){
            this.meetingHandlerService.pauseConsumer(procteeComponent.proctee.socketid, procteeComponent.videoConsumer, true);
          }
        }
      }
    }
  }

  // setPages() {
  //   this.pages = [];
  //   const numPages = Math.ceil(this.proctees.size / this.settings.procteesperpage);
  //   for (let i = 1; i <= numPages; i++) {
  //     this.pages.push(i);
  //   }
  // }

  //async addRemoteMediasoupTrack(id, track) {

  //  //let video = findRemoteVideo(id);
  //  //if (!video) {
  //  //    video = addRemoteVideo(id);
  //  //    //video.controls = '1';
  //  //}

  //  //if (video.srcObject) {
  //  //    video.srcObject.addTrack(track);
  //  //    return;
  //  //}
  //  //debugger;
    

  //  //let proctee = this.findProctee(id);
  //  //if (!proctee) {
  //  //  const { username, name } = await this.getMediasoupSocketIdUserDetails(id);
  //  //  proctee = new Proctee();
  //  //  proctee.socketid = id;
  //  //  proctee.username = username;
  //  //  proctee.name = name;
  //  //  proctee.muted = true;
  //  //  proctee.passporturl = this.passporturl + proctee.username;
  //  //  this.proctees.push(proctee);
  //  //  this.setPages();
  //  //  if (this.proctees.length == 1) {
  //  //    this.goToPage(1);
  //  //  }
  //  //  this.ref.detectChanges();
  //  //}

    
  //  if (track.kind === 'video') {
  //    proctee.videoTrack = track;
  //    this.setVideoTrack(proctee, track);
  //    //proctee.mediaStream.addTrack(track);
  //    //proctee.videoTrackSNo++;
  //    //this.ref.detectChanges();
  //  }
  //  else if (track.kind === 'audio') {
  //    //debugger;
  //    //track.enabled = false;
  //    proctee.audioTrack = track;

  //    this.setAudioTrack(proctee, track);
  //    //proctee.mediaStream.addTrack(track);
  //    //proctee.audioTrackSNo++;
  //  }

  //  //if this proctee is on the current page, resume its consumers here...mainly because the audio track will be added after the proctee has been initialised on the page
  //  if (document.querySelector(`#proctee_${proctee.socketid}`)) {
  //    this.resumeConsumers(proctee, false, proctee.muted ? 'video' : undefined);//undefined will resume all streams);
  //  }

  //  console.log(`added ${track.kind} track`);
  //}

  

  //removeRemoteVideo(socketid: string) {
  //  const div = document.querySelector(`#proctee_${socketid}`);
  //  //if (div) {
  //  //  div.remove();
      
  //  //}
  //  const proctee = this.findProctee(socketid);
  //  if (proctee) {
  //    if (proctee.audioTrack) {
  //      try {
  //        proctee.audioTrack.stop();
  //      } catch (error) {
  //        console.error(error);
  //      }
  //    }
  //    if (proctee.videoTrack) {
  //      try {
  //        proctee.videoTrack.stop();
  //      }
  //      catch (error) {
  //        console.error(error);
  //      }
  //    }

  //    let index = this.proctees.findIndex((tmpproctee: Proctee) => {
  //      return tmpproctee === proctee;
  //    });

  //    if (index !== -1) {
  //      //debugger;
  //      this.proctees.splice(index, 1);
  //      this.setPages();
  //      //this.ref.detectChanges();
  //    }
  //  }
  //}

  // async getMediasoupSocketIdUserDetails(mediasoupSocketId) {
  //   //debugger;
  //   let ret;
  //   if (!this.mediasoupSocketIds[mediasoupSocketId]) {
  //     const obj: any = await this.sendMediasoupControlRequest('getUserDetails', { id: mediasoupSocketId });

  //     this.mediasoupSocketIds[mediasoupSocketId] = { username: obj.username, name: obj.name };
  //   }
  //   ret = this.mediasoupSocketIds[mediasoupSocketId];

  //   console.log(`socket id ${mediasoupSocketId} => ${ret}`);

  //   return ret;
  // }

  // getVideoPlayer(socketid: string): HTMLVideoElement {
  //   const video: HTMLVideoElement = document.querySelector(`#proctee_${socketid} video`);
  //   return video;
  // }

  // getAudioPlayer(socketid: string): HTMLAudioElement {
  //   const audio: HTMLAudioElement = document.querySelector(`#proctee_${socketid} audio`);
  //   return audio;
  // }

  setproctorfullname(name)
  {
    this.proctorfullname=name;  
    $('#proctorname').modal('hide');

  }
  

}
