<main class="bg-gradient-to-bl from-primary/70 to-primary/10">
  <div
    class="bg-left-bottom bg-no-repeat bg-gradient-to-r from-primary to-primary h-screen w-full justify-center items-center sm:p-4 md:flex"
    style="background-image: url(/assets/images/login-bg.webp)"
  >
    <section
      class="rounded h-full bg-white/90 shadow-lg w-full py-12 px-4 items-center md:h-auto md:max-w-screen-sm md:w-screen-sm md:p-8"
    >
      <header class="flex pb-4 gap-8 justify-between">
        <h1 class="font-bold pb-4 text-2xl uppercase">Login</h1>
        <a href="/">
          <img src="/assets/images/logo.svg" alt="logo" class="mx-auto h-8" />
        </a>
      </header>

      <form
        #login="ngForm"
        name="login"
        (ngSubmit)="loginClicked()"
        class="pb-8"
      >
        <label class="block">
          <div class="text-xs pb-1 text-gray-500 uppercase">Username</div>
          <input
            required
            type="text"
            name="user"
            [(ngModel)]="username"
            placeholder="Username"
            class="border rounded bg-primary/10 text-sm w-full p-4"
          />
        </label>

        <label class="pt-4 pb-8 block">
          <div class="text-xs pb-1 text-gray-500 uppercase">Password</div>
          <input
            required
            type="password"
            name="password"
            placeholder="Password"
            [(ngModel)]="password"
            class="border rounded bg-primary/10 text-sm w-full p-4"
          />
        </label>

        <button
          type="submit"
          [disabled]="isLoading"
          class="rounded bg-primary/80 text-white w-full p-4 px-8 uppercase hover:bg-primary"
        >
          {{ isLoading ? "Please wait" : "Login" }}
        </button>
      </form>

      <footer class="border-t text-center text-sm">
        <div class="py-4">Or click here to</div>

        <a
          routerLink="/proctees"
          class="bg-primary rounded text-white w-full p-4 px-8 uppercase block hover:underline"
        >
          Monitor Exam
        </a>
      </footer>
    </section>
  </div>
</main>
