import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoggedInOnInit } from 'src/app/loggedInInit';
import { RecordingsService } from 'src/app/services/recordings.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-examvideos',
  templateUrl: './examvideos.component.html',
  styleUrls: ['./examvideos.component.css']
})
export class ExamvideosComponent extends LoggedInOnInit {
  examid: string = '';
  candidateno: string = '';
  recordings: any[] = [];
  processed: boolean;
  processing: boolean;
  loading: boolean;
  searchcomplete: boolean;
  message: string;
  url: string
  presignedurl: string;
  to: string
  constructor(private recordingservice: RecordingsService, private activatedroute: ActivatedRoute, private router: Router, private userservice: UserService, private toastr: ToastrService) {
    super(router, userservice, activatedroute);
    this.examid = this.activatedroute.snapshot.queryParams.examid ?? '';
    this.candidateno = this.activatedroute.snapshot.queryParams.candidateno ?? '';
  }


  ngOnInit() {

    const ret = super.ngOnInit();
    // $(".modal").on("click", function () {
    //   $('#confirmdownload').modal('hide');
    // });
    if (this.loggedIn()) {
      if (this.examid) {
        this.searchClicked();
      }
    }
    return ret;
  }
  onChangeEvent(event: any) {
    this.searchcomplete = false;
    this.message = "";
    this.to = "";

    // console.log(event.target.value);

  }
  filled() {
    return this.examid;
  }

  async searchClicked() {
    this.url = "";
    this.presignedurl = "";
    this.processed = false;
    this.loading=true
    const examid = this.examid;
    let data = await this.recordingservice.getRecordings(examid)
    if (data) {
      this.searchcomplete = true;
      this.processed = data.processed;
      this.url = `${environment.serviceurl2}/recording/downloadexamvideos?examid=${examid}&token`
    }
    this.loading=false;
  }

  async initiatedownload() {
    $('#confirmdownload').modal('hide');

    const examid = this.examid;
    this.processing = true;
    //var emailto= document.getElementById("emailto").innerHTML;
    const info: any = await this.recordingservice.initiatedownload(examid, this.to).toPromise()
      .catch((error) => {
        this.toastr.error(`${error.status} ${error.statusText}`);
        this.toastr.error(`${error.error}`);
        return;
      }).finally(() => this.processing = false);
    this.message = info.message;
    this.presignedurl = info.presignedurl;
    if (this.processed) {
      this.toastr.info("Download In Progress");
    }
    else
      this.toastr.success("Download Process Initiated ");
    if (this.presignedurl) {
      let element: any = document.getElementById('click_me');
      element.href = this.presignedurl;

      // var win = window.open(this.presignedurl, '_blank', "noopener,noreferrer");
      // if (win) {
      //   win.focus();
      // } else {
      //   alert('Please allow popups for this website');
      // }
      element.click()
    }
  }

  openconfirmmodal() {
    $('#confirmdownload').modal('show');
  }

}