import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ProcteeflagsComponent } from './pages/procteeflags/procteeflags.component';
import { ProcteesComponent } from './components/proctees/proctees.component';
import { VideosComponent } from './pages/videos/videos.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ExamvideoplayerComponent } from './pages/examvideoplayer/examvideoplayer.component';
import { ExamvideosComponent } from './pages/examvideos/examvideos.component';
import { VideodownloadComponent } from './components/videodownload/videodownload.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'proctees', component: ProcteesComponent },
  { path: 'home', component: HomeComponent },
  { path: 'videos', component: VideosComponent },
  { path: 'download', component: VideodownloadComponent },
  { path: 'examvideos', component: ExamvideosComponent },
  { path: 'examvideoplayer', component: ExamvideoplayerComponent },
  { path: 'flags', component: ProcteeflagsComponent },
  { path: 'payment', component: PaymentComponent },
];

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'legacy',
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
