import { ToastrService } from 'ngx-toastr';
import { LoggedInOnInit } from '../../loggedInInit';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
})
export class PaymentComponent extends LoggedInOnInit {
  units: number;
  amount: number = 0;
  unitPrice: number = 0;

  // showDialog = false;
  // paymentUrl = '';

  @ViewChild('paymentIframe') paymentIframe: ElementRef;

  constructor(
    private router: Router,
    private userservice: UserService,
    private paymentService: PaymentService,
    private activatedrouter: ActivatedRoute,
    private toastr: ToastrService
  ) {
    super(router, userservice, activatedrouter);
  }

  ngOnInit() {
    this.getUnitPrice();
    return super.ngOnInit();
  }

  updateAmount() {
    this.amount = this.units * this.unitPrice;
  }

  async getUnitPrice() {
    this.unitPrice = await this.paymentService.getUnitPrice();
  }

  async initiatePayment() {
    debugger;
    const paymentInfo = await this.paymentService.initiatePayment(this.units);
    location.href = paymentInfo.url;

    //   this.paymentIframe.nativeElement.src = url;
    // this.openPaymentIframe(paymentInfo.url);
  }

  // openPaymentIframe(url) {
  //   this.paymentIframe.nativeElement.src = url;
  //   // $('#paymentDialog').modal('show');
  //   this.showDialog = true;
  // }

  closePaymentIframe() {
    //   // $('#paymentDialog').modal('hide');
    //   this.showDialog = false;
  }

  async windowMessageReceived(event) {
    //debugger;
    if (event.data.type == 'paymentdone') {
      if (event.data.responsecode == '0') {
        this.toastr.success('Payment Successful');
      } else {
        if (isNaN(event.data.responsecode)) {
          this.toastr.error(event.data.reason, 'Payment Error', { timeOut: 0 });
        } else {
          this.toastr.warning(event.data.reason, 'Payment Processing...', {
            timeOut: 0,
          });
        }
      }
      this.closePaymentIframe();
    }
  }
}
