<app-dashboard-layout pageTitle="Payment">
  <div class="main-content">
    <div class="col-sm-12 form-content">
      <form #payment="ngForm" name="payment" class="max-w-screen-sm">
        <h2 class="max-w-md text-sm">
          Enter the amount of units you want to buy, and pay in it's Naira
          equivalent (I.e, Price &times; ₦{{ unitPrice }})
        </h2>

        <div class="form-group">
          <label class="pt-4 block">
            <div class="text-xs pb-1 text-gray-500 uppercase">Units</div>
            <input
              required
              type="number"
              name="units"
              [(ngModel)]="units"
              placeholder="Units"
              (ngModelChange)="updateAmount()"
              class="border rounded bg-primary/10 text-sm w-full p-4"
            />
          </label>

          <label class="pt-4 pb-8 block">
            <div class="text-xs pb-1 text-gray-500 uppercase">Price (₦)</div>
            <input
              required
              disabled
              type="text"
              name="amount"
              placeholder="0"
              [(ngModel)]="amount"
              class="border rounded bg-primary/10 text-sm w-full p-4"
            />
          </label>
        </div>

        <button
          type="submit"
          [disabled]="!units"
          (click)="initiatePayment()"
          class="bg-primary rounded text-white w-full p-4 px-8"
        >
          Make Payment
        </button>
      </form>
    </div>
  </div>
</app-dashboard-layout>
