<div class="flex max-h-screen w-full overflow-hidden">
  <aside class="bg-white h-screen shadow-xl md:w-3/12">
    <header class="border-b border-primary flex p-8 gap-8 justify-between">
      <a href="/">
        <img src="/assets/images/logo.svg" alt="logo" class="mx-auto h-8" />
      </a>
    </header>

    <div class="flex flex-col text-primary pb-8">
      <a
        [routerLink]="link.slug"
        *ngFor="let link of links"
        routerLinkActive="bg-primary text-white"
        [routerLinkActiveOptions]="{ exact: true }"
        class="border-b border-primary flex text-sm py-6 px-8 gap-4 uppercase items-center hover:bg-primary/75 hover:text-white"
      >
        <div class="text-xl material-icons">{{ link.icon }}</div>
        <div>
          {{ link.title }}
        </div>
      </a>
    </div>
  </aside>

  <div class="h-screen bg-primary/5 w-full">
    <header
      class="bg-gradient-to-b flex from-primary/75 to-primary/50 text-white p-8 pb-48 justify-between items-center lg:px-12"
    >
      <div class="font-medium text-xl uppercase">{{ pageTitle }}</div>
      <div>
        <div
          class="bg-cover rounded-full bg-gray-200 h-12 w-12"
          style="background-image: url('https://www.gravatar.com/avatar/?d=mp')"
        ></div>
      </div>
    </header>

    <main class="h-full px-8 pb-20 lg:px-12">
      <div
        class="bg-white rounded-lg flex-grow h-full -mt-40 p-8 overflow-y-auto"
      >
        <ng-content></ng-content>
      </div>
    </main>
  </div>
</div>
