<div class="content-wrapper">
    <div class="search-result" *ngIf="loading">
        <div class="col-sm-10 col-sm-offset-1 mx-auto mt-5" style="text-align:center;">
            <span class="mx-2">
                <img height="50vh" *ngIf="loading" src="../../../assets/images/loading.gif">
            </span>
            <h5>Please Wait Video Is Being Processed</h5>
        </div>
    </div>
    <a href="{{url}}" id="click_me" hidden></a>
</div>
