<div class="settings">
  <input type="text" placeholder="access code..." [(ngModel)]="accesscode" class="norightmargin" (keydown.enter)="connectClicked()" />
  <input type="text" placeholder="Full Name" readonly  class="norightmargin" value="{{proctorfullname}}" />
  <input type="button" [disabled]="procteesComponent.connecting" (click)="connectClicked()" value="Connect" *ngIf="!procteesComponent.connected"/>
  <input type="button" (click)="disconnectClicked()" value="Disconnect" *ngIf="procteesComponent.connected"/>
  Proctees per Page:
  <select [(ngModel)]="settings.procteesperpage" (ngModelChange)="numProcteesChanged()">
    <option>1</option>
    <option>3</option>
    <option>6</option>
    <option>9</option>
    <option>12</option>
  </select>
  Filter Page by:
  <select [(ngModel)]="settings.filterby" (ngModelChange)="filterByChanged()">
    <option value="" selected>None</option>
    <option value="highlighted">Highlighted</option>
    <option value="attentionrequested">Requesting attention</option>
    <option value="proctorpaused">Paused</option>
    <option value="aiFlagged">Ai-Flagged</option>
  </select>
  <span class="ml-3">Proctees count: {{candidates}}</span>
  <span class="ml-3" [ngClass]="{'text-danger': requireAttention > 0}">Proctees requesting attention: {{requireAttention}}</span>
  <span class="ml-3" [ngClass]="{'text-danger': aiFlags > 0}">A.I Flags: {{aiFlags}}</span>
  <span style="display: none;">
    Seconds per Page:
    <input type="number" [(ngModel)]="settings.secondsperpage" (ngModelChange)="secondsPerPageChanged()" />
    <label>Auto Switch Page:</label>
    <input type="checkbox" [(ngModel)]="settings.autoswitchpage" (ngModelChange)="autoswitchChanged()" />
    <label>Search:</label>
    <input type="text" placeholder="search..." disabled />
  </span>
</div>
