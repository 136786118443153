import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoggedInOnInit } from 'src/app/loggedInInit';
import { UserService } from 'src/app/services/user.service';
import { RecordingsService } from '../../services/recordings.service';

var $: any;
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})

export class VideosComponent extends LoggedInOnInit {
  examid: string = '';
  candidateno: string = '';
  recordings: any[] = [];

  constructor(private recordingservice: RecordingsService, private activatedroute: ActivatedRoute, private router: Router, private userservice: UserService,private toastr:ToastrService) {
    super(router, userservice, activatedroute);
    this.examid = this.activatedroute.snapshot.queryParams.examid ?? '';
    this.candidateno = this.activatedroute.snapshot.queryParams.candidateno ?? '';
  }
  

  ngOnInit() {
  
    const ret = super.ngOnInit();
    if(this.loggedIn()){
      if(this.examid && this.candidateno){
        this.searchClicked();
      }
    }
    return ret;
  }

  filled(){
    return this.examid && this.candidateno;
  }

  async searchClicked(){
    const examid = this.examid;
    const candidateno = this.candidateno;
if(!candidateno)
{

}
  else{  const data = await this.recordingservice.getRecordings(examid, candidateno);
    const times: any[] = data[examid][candidateno];

    this.recordings = [];

    for(const time of times){
      const url = this.recordingservice.getRecordingUrl(examid, candidateno, time);
      this.recordings.push({candidateno: candidateno, time: time, url: url, datetimestring: new Date(parseInt(time)).toString()});
    }}
  }
}
