// import { ParticipantAudioComponent } from '../components/participant-audio/participant-audio.component';
// import { ParticipantVideoComponent } from '../components/participant-video/participant-video.component';

export class Participant{
    static silenceVolume = -1000000;
    socketid: string;
    //audiomuted: boolean = false;
    //videomuted: boolean = false;
    username: string = '';
    name: string = '';
    photourl: string = '';
    host = false;
    videoProducerId: string;
    audioProducerId: string;
    //screenShareProducerId;
    //videoConsumer: any;
    //audioConsumer: any;
    //screenShareConsumer: any;
    videoAvailable: boolean;
    audioAvailable: boolean;
    me: boolean = false;
    // participantVideoComponent: ParticipantVideoComponent;
    // participantAudioComponent: ParticipantAudioComponent;
    volume: number = Participant.silenceVolume;
    order: number = 100000;
    focused: boolean = false;
    handRaised: boolean = false;
    unlocked: boolean = false;
    data: any
    // width = '100px';

    // getStyle(){
    //     return {
    //         order: this.order,
    //         width: this.width
    //     }
    // }

    
    //screenShareAvailable: boolean;
}