import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  ActivatedRoute,
  DefaultUrlSerializer,
  PRIMARY_OUTLET,
  Router,
  UrlSerializer,
} from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  //company: string = '';
  isLoading = false;
  url: string = '';
  constructor(
    private userservice: UserService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {}

  async loginClicked() {
    try {
      this.isLoading = true;
      const data = await this.userservice.authenticate(
        this.username,
        this.password
      );
      const token = data.token;
      const company = data.company;
      //this.userservice.username = this.username;
      //this.userservice.password = this.password;
      this.cookieService.set('authToken', token);
      this.cookieService.set('authCompany', company);
      this.isLoading = false;
      this.url = this.activatedroute.snapshot.queryParams.url;
      if (this.url) {
        const urltree = new DefaultUrlSerializer().parse(this.url);
        const segments = urltree.root.children[PRIMARY_OUTLET].segments;
        let urlpath: string = '/'; //using any because the current typescript definitions don't have trimRight
        for (const segment of segments) {
          urlpath += segment.path += '/';
        }
        //if(urlpath.endsWith('/')){
        urlpath = urlpath.substring(0, urlpath.length - 1);
        //}
        this.router.navigate([urlpath], { queryParams: urltree.queryParams });
      } else {
        this.router.navigate(['/home']);
      }
    } catch (error) {
      //debugger;
      this.isLoading = false;
      alert(error.error.error);
    }
  }
}
