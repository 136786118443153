import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoggedInOnInit } from 'src/app/loggedInInit';
import { RecordingsService } from 'src/app/services/recordings.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-videodownload',
  templateUrl: './videodownload.component.html',
  styleUrls: ['./videodownload.component.css']
})
export class VideodownloadComponent extends LoggedInOnInit {

  examid: any;
  candidateno: any;
  time: any;
  loading: boolean;
  checkfileexistinterval: any;
  url: string;

  constructor(private recordingservice: RecordingsService, private activatedroute: ActivatedRoute, private router: Router, private userservice: UserService, private toastr: ToastrService) {
    super(router, userservice, activatedroute);
    this.examid = this.activatedroute.snapshot.queryParams.examid ?? '';
    this.candidateno = this.activatedroute.snapshot.queryParams.candidateno ?? '';
    this.time = this.activatedroute.snapshot.queryParams.time ?? '';
  }
  async ngOnInit() {
    const ret = super.ngOnInit();
    if (this.loggedIn()) {
      if (this.examid && this.candidateno && this.time) {
        this.url = this.recordingservice.getRecordingUrl(this.examid, this.candidateno, this.time);

        await this.download()
      }
    }
    return ret;
  }
  async checkfileexists() {
    let exists = await this.recordingservice.checkmp4exists(this.examid, this.candidateno, this.time).toPromise()
      .catch(
        (error) => {
          this.toastr.error(`${error.status} ${error.statusText}`);
          this.toastr.error(`${error.error}`);
          return;
        });
    if (exists) {
      this.loading = false;
      if (this.checkfileexistinterval) {
        clearInterval(this.checkfileexistinterval);
      }
      await this.download()
    }
  }
  async download() {
    if (this.url) {
      this.loading = true;
      var res: any = await this.recordingservice.downloadvideo(this.url).toPromise().
        catch((error) => {
          this.toastr.error(`${error.status} ${error.statusText}`);
          this.toastr.error(`${error.error}`);
          this.loading = false;
          this.router.navigate(['/videos'], { queryParams: { examid: this.examid, candidateno: this.candidateno } });
          return;
        });
      if (!res.exists && res.exists != undefined) {
        if (!this.checkfileexistinterval) {
          this.checkfileexistinterval = setInterval(() => {
            this.checkfileexists();
          }, 60000)
        }
      }
      else {
        let downloadurl = res.url
        this.initiatedownload(downloadurl)
      }

    }

  }

  initiatedownload(downloadurl: any) {
    this.loading = false
    let element: any = document.getElementById('click_me');
    element.href = downloadurl;
    element.click()
    setTimeout("window.close()",3000) 
  }

  ngOnDestroy() {
    if (this.checkfileexistinterval) {
      clearInterval(this.checkfileexistinterval);
    }
  }
}
