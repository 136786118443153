<app-dashboard-layout pageTitle="Recordings">
  <div class="content-wrapper">
    <form
      #login="ngForm"
      name="getrecordings"
      (ngSubmit)="searchClicked()"
      class="rounded-lg flex bg-primary/10 border-primary/20 border-4 text-sm mb-8 w-full p-4 gap-4 items-center"
    >
      <div class="flex flex-grow gap-4 items-center">
        <input
          placeholder="Exam ID"
          required
          type="text"
          name="examid"
          [(ngModel)]="examid"
          class="bg-white rounded p-4 w-1/2 focus:bg-white"
        />

        <input
          placeholder="Candidate No"
          type="text"
          required
          name="candidateno"
          [(ngModel)]="candidateno"
          class="rounded p-4 w-1/2"
        />
      </div>

      <button
        type="submit"
        [disabled]="!filled()"
        class="bg-primary rounded text-white p-2 px-8"
      >
        <div class="text-xl material-icons">search</div>
      </button>
    </form>

    <div class="search-result" *ngIf="recordings.length > 0">
      <div class="mx-auto col-sm-10 col-sm-offset-1">
        <p class="search-result-count">
          Showing {{ recordings.length }} entries
        </p>
        <div class="table-responsive">
          <table datatable class="table table-dark proctor-monitor-table">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Candidate No.</th>
                <th>Download Link</th>
                <th>Play Link</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let recording of recordings; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  <b>{{ recording.candidateno }}</b>
                </td>
                <td>
                  <!-- <a href="{{recording.url}}" target="_blank">{{recording.datetimestring}}</a> -->
                  <a
                    routerLink="/download"
                    target="_blank"
                    [queryParams]="{
                      candidateno: recording.candidateno,
                      examid: this.examid,
                      time: recording.time
                    }"
                    >{{ recording.datetimestring }}</a
                  >
                </td>
                <td>
                  <a
                    routerLink="/examvideoplayer"
                    target="_blank"
                    [queryParams]="{
                      candidateno: recording.candidateno,
                      examid: this.examid,
                      time: recording.time
                    }"
                  >
                    Play Recording
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- <table>
        <th>Candidate No.</th>
        <th>Video Id</th>
        <tr>
          <th><b>UTME2021/456</b></th>
          <td><a href="#" target="_blank">Jan 24th 2020</a></td>
        </tr>
        <tr>
          <th><b>UTME2021/456</b></th>
          <td><a href="#" target="_blank">Jan 24th 2020</a></td>
        </tr>
    </table> -->
    <!-- <div class="search-result">
      <div class="mx-auto col-sm-10 col-sm-offset-1">
        <p class="search-result-count">Showing 1 - 25 of 22000 entries</p>
        <div class="table-responsive">
          <table class="table table-dark proctor-monitor-table">
            <thead>
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Candidate No</th>
                <th scope="col">Video Id</th>
                <th scope="col">Date & Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>UTME021001</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>UTME021002</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>UTME021003</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>UTME021004</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>UTME021005</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>UTME021006</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">7</th>
                <td>UTME021007</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">8</th>
                <td>UTME021008</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">9</th>
                <td>UTME021009</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">10</th>
                <td>UTME021010</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">11</th>
                <td>UTME021011</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">12</th>
                <td>UTME021012</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">13</th>
                <td>UTME021013</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">14</th>
                <td>UTME021014</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">15</th>
                <td>UTME021015</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">16</th>
                <td>UTME021016</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">17</th>
                <td>UTME021017</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">18</th>
                <td>UTME021018</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">19</th>
                <td>UTME021019</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">20</th>
                <td>UTME021020</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">21</th>
                <td>UTME021021</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">22</th>
                <td>UTME021022</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">23</th>
                <td>UTME021023</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">24</th>
                <td>UTME021024</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
              <tr>
                <th scope="row">25</th>
                <td>UTME021025</td>
                <td><a href="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" target="_blank">https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4</a></td>
                <td>Feb 14th, 2021 - 07:45am</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
    <!-- pagination -->
    <!-- <div class="monitor-exam-table-pagination">
      <div class="mx-auto col-sm-10 col-sm-offset-1">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 pager-left">
            <p>Showing 1 - 25 of 22000 entries</p>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 page-right">
            <ul>
              <li><i class="fa fa-angle-left"></i></li>
              <li>1</li>
              <li><i class="fa fa-angle-right"></i></li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</app-dashboard-layout>
