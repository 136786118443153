<div id="proctee_{{proctee.socketid}}" class="proctee" #container [ngClass]="{'proctee_highlighted': proctee.displayhighlight }">  
  <div class="proctee_innercontainer" [ngClass] ="{'bg-danger text-white p-1': proctee.handRaised}">
    <div class=" showreason" (mouseover)="showreason(proctee.username)" (mouseout)="showreason(proctee.username)">
      <span class="popuptext" id={{proctee.username}}>{{proctee.pausereason}}</span>
      <i *ngIf="(proctee.adminpaused || proctee.proctorpaused) && proctee.pausereason" style="color: #007bff;cursor: default; float: right; font-size: 16px;" class=" btn fa fa-info-circle popup">
      </i>
    </div>
    <div class="proctee_mediadiv">      
      <div class="proctee_imagediv"><img src="{{proctee.passporturl}}" /></div>
      <div class="proctee_videodiv">
        <video playsinline autoplay muted #video>
        </video>
      </div>
      <audio #audio autoplay muted></audio>
    </div>
    <div class="proctee_controlsandinfo">
      <div class="proctee_controls">
        <button [disabled]="!proctee.connected" class="btn btn-warning proctee_flagbutton fa fa-exclamation-circle" (click)="flagCandidate()" title="Flag Candidate"></button>
        <span *ngIf="proctee.disconnected && proctee.connected" class="ml-2">Disconnected</span>
        <span *ngIf="proctee.disconnected && !proctee.connected" class="ml-2">Connecting...</span>
        <span *ngIf="this.timeleft !== undefined" class="col-sm-12 col-md-12 clock_timer" style="color:white" title="Candidate Time Left">
          <i class="fa mr-2" style="color: red;">&#xf017;</i>
          <span style="background-color: red;">
            <countdown #countdown [config]="{leftTime: this.timeleft, notify: 0}" (event)="handleEvent($event)"></countdown>
          </span>
        </span>
        <button [disabled]="proctee.disconnected" (click)="highlightCandidateBtnClicked()" class="btn btn-primary proctee_hightlight" [ngClass]="{'fa': true, 'fa-paint-brush': true, 'highlighted': !proctee.highlighted }" title="Highlight"></button>
        <button [disabled]="proctee.removed" class="btn btn-primary proctee_sendmessage fa fa-comment" (click)="openChat()" title="Send Message"></button>
        <button [disabled]="proctee.disconnected" (click)="toggleAudio()" class="btn btn-primary" [ngClass]="{'proctee_mutebutton': true, 'fa': true, 'fa-volume-up': !proctee.muted, 'fa-volume-off': proctee.muted, 'muted': proctee.muted}" title="Listen"></button>
        <button [disabled]="proctee.disconnected" (click)="toggleSpeakingTo()" class="btn btn-primary" [ngClass]="{'proctee_speaktobutton': true, 'fa': true, 'fa-microphone': true, 'speakingTo': proctee.speakingTo}" title="Speak"></button>
        <button [disabled]="proctee.disconnected || proctee.adminpaused" (click)="pauseCandidate()" class="btn btn-primary mr-2" [ngClass]="{'proctee_pausebutton': true, 'fa': true, 'fa-pause': true, 'paused': proctee.proctorpaused || proctee.adminpaused}" title="Pause/Unpause"></button>
        <button [disabled]="proctee.removed || this.restartingStream" (click)="triggerRestartProcteeStreams()" class="btn btn-primary proctee_restartstreams fa fa-refresh" title="Refresh Video and Audio"></button>
        <button *ngIf="proctee.aiFlags.length > 0" [disabled]="proctee.disconnected" [ngbPopover]="popContent" [popoverTitle]="popTitle"
          (dblclick)="dismissAiFlag()" class="btn btn-danger fa fa-flag ai-flags-btn mr-2" title="Ai-Flags | Double click to dismiss">
          <span class="ai-flag-count ml-1">({{proctee.aiFlagsCount}})</span>
        </button>  
      </div>
      <div class="proctee_infodiv">
        <div class="proctee_username"><span class="proctee_label_span">Id:</span> {{proctee.username}}</div>
        <div class="proctee_name"><span class="proctee_label_span">Name:</span> {{proctee.name}}</div>
      </div>
    </div>
    <input type="hidden" value="{{proctee.muted}}" />
    <input type="hidden" class="audioproducerid" value="{{proctee.audioProducerId}}" />
    <input type="hidden" class="videoproducerid" value="{{proctee.videoProducerId}}" />
    <!--<input type="hidden" value="{{proctee.videoTrackSNo}}" />
    <input type="hidden" value="{{proctee.audioTrackSNo}}" />
    <input type="hidden" value="{{proctee.videoTrack}}" />
    <input type="hidden" value="{{proctee.audioTrack}}" />-->
  </div>
</div>

<ng-template #popContent>
  <ul style="list-style: none">
    <li *ngFor="let flag of proctee.aiFlags">{{flag.reason}} (x {{flag.count}}). "{{getTime(flag.time)}}"</li>
  </ul>  
</ng-template>
<ng-template #popTitle><span>Ai-Flags</span> <button (click)="dismissAiFlag()" style="float: right;" class="btn btn-danger fa fa-close ml-2" title="Dismiss"></button></ng-template>
