import { ProcteeComponent } from "../components/proctee/proctee.component";

export class Proctee {
  socketid: string;
  username: string;
  name: string;
  videoProducerId: string;
  audioProducerId: string;
  procteeComponent: ProcteeComponent;
  muted: boolean = true;
  speakingTo: boolean = false;
  passporturl: string = '';
  videoAvailable: boolean = false;
  audioAvailable: boolean = false;
  examid: string = '';
  visible: boolean;
  connected: boolean = false;//this is only used to know if any initial producer has been received...its only when the proctee has been able to produce that we want to allow the proctor to flag the proctee
  disconnected: boolean = true;
  removed = false;
  handRaised: boolean = false;
  highlighted: boolean = false;
  displayhighlight: boolean = false;
  timeleft: number | undefined = undefined;
  proctorpaused: boolean = false;
  adminpaused: boolean = false;
  pausereason: string = '';
  aiFlags: AiFlag[] = [];
  aiFlagsCount: number = 0;
}

export interface AiFlag{
  reason:string
  count:number,
  time: number
}


