import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
})
export class DashboardLayoutComponent implements OnInit {
  constructor() {}
  @Input() pageTitle = '';

  links = [
    { title: 'Dashboard', slug: '/home', icon: 'home' },
    { title: 'Search Recordings', slug: '/videos', icon: 'image_search' },
    {
      title: 'Download Recordings',
      slug: '/examvideos',
      icon: 'missed_video_call',
    },
    { title: 'Flagged Candidates', slug: '/flags', icon: 'flag' },
    { title: 'Payment', slug: '/payment', icon: 'account_balance_wallet' },
    { title: 'Logout', slug: '/', icon: 'logout' },
  ];

  ngOnInit(): void {}
}
