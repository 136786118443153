import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { LoggedInOnInit } from '../../loggedInInit';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent extends LoggedInOnInit {
  constructor(
    private router: Router,
    private userservice: UserService,
    private activatedrouter: ActivatedRoute
  ) {
    super(router, userservice, activatedrouter);
  }

  ngOnInit() {
    return super.ngOnInit();
  }
}
