import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, Host, ViewChild, ElementRef } from '@angular/core';
import { Proctee } from '../../models/Proctee';
import { ProcteesComponent } from '../proctees/proctees.component';
import { UtilService } from '../../services/util.service';
import { ToastrService } from 'ngx-toastr';
import { CountdownComponent } from 'ngx-countdown';
import { MeetingHandlerService } from 'src/app/services/meeting-handler.service';
import * as moment from 'moment';
declare const $: any;
@Component({
  selector: 'app-proctee',
  templateUrl: './proctee.component.html',
  styleUrls: ['./proctee.component.css', '../../../assets/css/font-awesome.min.css']
})
export class ProcteeComponent implements OnInit {
  //@Input() proctee: Proctee;
  __proctee: Proctee;
  @Output() audioToggled: EventEmitter<Proctee> = new EventEmitter<Proctee>();
  @Output() speakingToggled: EventEmitter<Proctee> = new EventEmitter<Proctee>();
  @Output() streamSettingRequired: EventEmitter<Proctee> = new EventEmitter<Proctee>();
  //@Output() procteeDestroyed: EventEmitter<Proctee> = new EventEmitter<Proctee>();
  @Output() flagCandidateClicked: EventEmitter<Proctee> = new EventEmitter<Proctee>();
  @Output() highlightCandidateClicked: EventEmitter<Proctee> = new EventEmitter<Proctee>();
  @Output() pauseCandidateClicked: EventEmitter<Proctee> = new EventEmitter<Proctee>();

  @ViewChild('audio') audio: ElementRef;
  @ViewChild('video') video: ElementRef;
  @ViewChild('container') container: ElementRef;
  @ViewChild('countdown') countdown: CountdownComponent;
  audioConsumer: any;
  videoConsumer: any;
  visible: boolean = false;
  timeleftinterval: any;
  timeleft: number | undefined = undefined;
  countdowntimeout: any;
  pausestatuscheckinterval: any;
  restartingStream: boolean = false;

  @Input()
  set proctee(value: Proctee) {
    this.__proctee = value;
    this.__proctee.procteeComponent = this;
  }

  get proctee() {
    return this.__proctee;
  }

  constructor(@Host() private parent: ProcteesComponent, private util: UtilService, private toastr: ToastrService, private meetingHandlerService: MeetingHandlerService) {
  }

  ngOnInit(): void {
    //console.log(this.parent);
    console.log(`proctee ${this.proctee.username} initialised`);
    // if (this.proctee.videoConsumer) {
    //   this.setVideoTrack(this.proctee.videoConsumer.track);
    // }
    // if (this.proctee.audioConsumer) {
    //   this.setAudioTrack(this.proctee.audioConsumer.track);
    // }
    //this.proctee.procteeComponent = this;
    //this.updateStream();

    this.gettimeleft();
    const mythis = this;
    this.timeleftinterval = setInterval(() => {
      this.gettimeleft();
    }, 1000);
  }

  gettimeleft() {
    this.timeleft = this.proctee.timeleft;
    const mythis = this;
    if (!this.proctee.proctorpaused && !this.proctee.adminpaused) {
      this.countdowntimeout = setTimeout(() => {
        if (this.countdown) this.countdown.resume();
      });
    }
  }

  handleEvent(e) {
    //console.log(e)
    //if (e.action == "done") {
    //  console.log("time elapsed");
    //}
    //if (e.action == "notify") {
    //  console.log("warning");
    //}
  }

  ngAfterContentInit() {    
    //const video: HTMLVideoElement = document.querySelector(`#proctee_${this.proctee.socketid} video`);
    //if (video) {
    //  if (this.proctee.videoTrack && video.srcObject == null) {
    //    const mediaStream = new MediaStream([this.proctee.videoTrack]);
    //    video.srcObject = mediaStream;
    //  }
    //}
  }

  async ngAfterViewInit() {
    //console.log(`proctee ${this.proctee.socketid} initialised`);
    //console.log({ socketId: this.proctee.socketid, username: this.proctee.username, audioProducerId: this.proctee.audioProducerId, videoProducerId: this.proctee.videoProducerId });
    // const video: HTMLVideoElement = document.querySelector(`#proctee_${this.proctee.socketid} video`);
    // const audio: HTMLAudioElement = document.querySelector(`#proctee_${this.proctee.socketid} audio`);
    // if ((video && !video.srcObject) || (audio && !audio.srcObject)) {
    //   this.streamSettingRequired.emit(this.proctee);
    //   //if (this.proctee.videoTrack && video.srcObject == null) {
    //   //  const mediaStream = new MediaStream([this.proctee.videoTrack]);
    //   //  video.srcObject = mediaStream;
    //   //}
    // }
    await this.setStream('video');
    await this.setStream('audio');
    await this.gettimeleft();
    this.pausestatuscheckinterval = setInterval(() => {
      const mythis = this;
      if (this.proctee.proctorpaused || this.proctee.adminpaused) {
        this.gettimeleft();

        if (this.timeleftinterval) clearInterval(this.timeleftinterval);
        if (this.countdowntimeout) clearTimeout(this.countdowntimeout);
        if (this.countdown) mythis.countdown.pause();
      }
      else// if(!mythis.timeleftinterval || !this.countdowntimeout)
      {
        // if(this.timeleftinterval) clearInterval(this.timeleftinterval);
        // if(this.countdowntimeout) clearTimeout(this.countdowntimeout);
        //if(!this.timeleftinterval){ this.timeleftinterval = setInterval(() => {
        this.gettimeleft();
        // }, 1000);}
        // if(this.countdown) this.countdown.resume();
      }
      this.toggleDisplayHighlight();
    }, 1000);
  }

  async ngOnDestroy() {
    //this.procteeDestroyed.emit(this.proctee);
    this.meetingHandlerService.closeConsumer(this.proctee.socketid, this.audioConsumer, false);
    this.meetingHandlerService.closeConsumer(this.proctee.socketid, this.videoConsumer, false);
    // if(this === this.proctee.procteeComponent){
    //   this.proctee.procteeComponent = null;
    // }
    // else{
    //   console.warn('component does not match current component');
    // }
    if (this.timeleftinterval) {
      clearInterval(this.timeleftinterval);
    }
    if (this.pausestatuscheckinterval) {
      clearInterval(this.pausestatuscheckinterval);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {    
    //debugger;
    //console.log(`proctee ${this.proctee.socketid} changed`);
    //console.log({ socketId: this.proctee.socketid, username: this.proctee.username, audioProducerId: this.proctee.audioProducerId, videoProducerId: this.proctee.videoProducerId });
    //this.updateStream();
  }

  // async ngAfterContentChecked() {
  //   //console.log(`proctee ${this.proctee.socketid} after content checked`);

  //   if (!this.proctee.pendingAudioProducerId || this.proctee.audioProducerId !== this.proctee.pendingAudioProducerId) {
  //     //console.log({ socketId: this.proctee.socketid, username: this.proctee.username, audioProducerId: this.proctee.audioProducerId, videoProducerId: this.proctee.videoProducerId });
  //     console.log('pendingAudioProducer: ', this.proctee.pendingAudioProducerId);
  //     //if (this.proctee.pendingAudioProducerId) {
  //       console.log(`${this.proctee.audioProducerId} !== ${this.proctee.pendingAudioProducerId}`);
  //     //}
  //     if (this.proctee.audioConsumer) {
  //       //close old consumer
  //       this.parent.closeConsumer(this.proctee.socketid, this.proctee.audioConsumer, false);
  //       this.proctee.audioConsumer = null;
  //     }
  //     if (this.proctee.audioProducerId && this.proctee.audioProducerId !== this.proctee.pendingAudioProducerId) {
  //       //create new consumer
  //       this.proctee.pendingAudioProducerId = this.proctee.audioProducerId;
  //       console.log(`this.proctee.pendingAudioProducerId = ${this.proctee.audioProducerId}`);
  //       this.proctee.audioConsumer = await this.parent.createConsumer(this.proctee.socketid, this.proctee.audioProducerId, 'audio');
  //       //TODO: we need to track premature closure
  //       //this.proctee.pendingAudioProducerId = '';
  //       await this.parent.resumeConsumer(this.proctee.socketid, this.proctee.audioConsumer, true);
  //       this.proctee.audioConsumer.resume();
  //       this.setAudioTrack(this.proctee.audioConsumer.track);
  //     }
  //   }
  //   if (!this.proctee.pendingVideoProducerId || this.proctee.videoProducerId !== this.proctee.pendingVideoProducerId) {
  //     console.log('pendingVideoProducer: ', this.proctee.pendingVideoProducerId);
  //     //if (this.proctee.pendingVideoProducerId) {
  //       console.log(`${this.proctee.videoProducerId} !== ${this.proctee.pendingVideoProducerId}`);
  //     //}
  //     if (this.proctee.videoConsumer) {
  //       //close old consumer
  //       this.parent.closeConsumer(this.proctee.socketid, this.proctee.videoConsumer, false);
  //       this.proctee.videoConsumer = null;
  //     }
  //     if (this.proctee.videoProducerId && this.proctee.videoProducerId !== this.proctee.pendingVideoProducerId) {
  //       //create new consumer
  //       this.proctee.pendingVideoProducerId = this.proctee.videoProducerId;
  //       this.proctee.videoConsumer = await this.parent.createConsumer(this.proctee.socketid, this.proctee.videoProducerId, 'video');
  //       //this.proctee.pendingVideoProducerId = '';
  //       await this.parent.resumeConsumer(this.proctee.socketid, this.proctee.videoConsumer, true);
  //       this.proctee.videoConsumer.resume();
  //       this.setVideoTrack(this.proctee.videoConsumer.track);
  //     }
  //   }
  // }

  async setStream(kind: string) {
    //debugger;
    if (kind == 'video') {
      if (this.proctee.videoProducerId && (!this.videoConsumer || this.videoConsumer.closed || this.videoConsumer.paused || (this.videoConsumer.producerId != this.proctee.videoProducerId))) {
        // if(this.videoConsumer){
        //   await this.closeConsumer();
        // }
        this.visible = false;
        console.log('proctee changes: ', this.proctee);
        let consumer;
        if (this.meetingHandlerService.videoConsumersMap.has(this.proctee.videoProducerId)) {
          consumer = this.meetingHandlerService.videoConsumersMap.get(this.proctee.videoProducerId);
        }
        if (consumer && !consumer.closed) {
          this.videoConsumer = consumer;
        }
        else {
          this.videoConsumer = (await this.meetingHandlerService.createConsumer(this.proctee.socketid, this.proctee.videoProducerId, kind)).consumer;
          //this.parent.resumeConsumer(this.proctee.socketid, this.videoConsumer, false);
          this.meetingHandlerService.videoConsumersMap.set(this.proctee.videoProducerId, this.videoConsumer);
        }

        let replacestream = true;
        if (this.video.nativeElement.srcObject) {
          let currentVideoTrack = this.video.nativeElement.srcObject.getVideoTracks()[0];
          if (currentVideoTrack && currentVideoTrack.id == this.videoConsumer.track.id) {
            replacestream = false;
          }
        }

        if (replacestream) {
          this.video.nativeElement.srcObject = new MediaStream([this.videoConsumer.track]);
        }
        this.proctee.videoAvailable = true;
        const mythis = this;
        this.video.nativeElement.onplaying = function () {
          mythis.visible = true;
          mythis.video.nativeElement.onplaying = null;
        }
      }
      else if (!this.proctee.videoProducerId && this.videoConsumer) {
        //this.parent.closeConsumer(this.proctee.socketid, this.videoConsumer);
        //await this.closeConsumer();
        this.visible = false;
        this.video.nativeElement.srcObject = null;
        this.proctee.videoAvailable = false;
        this.videoConsumer = null;
      }
      else {
        //debugger;
        //await this.parent.resumeConsumer(this.proctee.socketid, this.videoConsumer);
        //console.log('here');
      }
    }
    else if (kind == 'audio') {
      if (this.proctee.audioProducerId && (!this.audioConsumer || this.audioConsumer.closed || this.audioConsumer.paused || (this.audioConsumer.producerId != this.proctee.audioProducerId))) {
        // if(this.audioConsumer){
        //   await this.closeConsumer();
        // }
        this.visible = false;
        console.log('proctee changes: ', this.proctee);
        let consumer;
        if (this.meetingHandlerService.audioConsumersMap.has(this.proctee.audioProducerId)) {
          consumer = this.meetingHandlerService.audioConsumersMap.get(this.proctee.audioProducerId);
        }
        if (consumer && !consumer.closed) {
          this.audioConsumer = consumer;
        }
        else {
          this.audioConsumer = (await this.meetingHandlerService.createConsumer(this.proctee.socketid, this.proctee.audioProducerId, kind)).consumer;
          //this.parent.resumeConsumer(this.proctee.socketid, this.audioConsumer, false);
          this.meetingHandlerService.audioConsumersMap.set(this.proctee.audioProducerId, this.audioConsumer);
        }

        let replacestream = true;
        if (this.audio.nativeElement.srcObject) {
          let currentAudioTrack = this.audio.nativeElement.srcObject.getAudioTracks()[0];
          if (currentAudioTrack && currentAudioTrack.id == this.audioConsumer.track.id) {
            replacestream = false;
          }
        }

        if (replacestream) {
          this.audio.nativeElement.srcObject = new MediaStream([this.audioConsumer.track]);
        }
        this.proctee.audioAvailable = true;
        const mythis = this;
        this.audio.nativeElement.onplaying = function () {
          mythis.visible = true;
          mythis.audio.nativeElement.onplaying = null;
        }
      }
      else if (!this.proctee.audioProducerId && this.audioConsumer) {
        //this.parent.closeConsumer(this.proctee.socketid, this.audioConsumer);
        //await this.closeConsumer();
        this.visible = false;
        this.audio.nativeElement.srcObject = null;
        this.proctee.audioAvailable = false;
        this.audioConsumer = null;
      }
      else {
        //debugger;
        //await this.parent.resumeConsumer(this.proctee.socketid, this.audioConsumer);
        console.log('here');
      }
    }
  }

  // private setAudioTrack(track: MediaStreamTrack) {
  //   const audio: HTMLAudioElement = this.parent.getAudioPlayer(this.proctee.socketid);
  //   if (audio) {
  //     const mediaStream = new MediaStream([track]);
  //     try {
  //       //audio.muted = true;
  //       audio.srcObject = mediaStream;
  //       //audio.play();
  //     }
  //     catch (error) {
  //       console.log(error);
  //     }
  //   }
  // }

  // private setVideoTrack(track: MediaStreamTrack) {
  //   const video: HTMLVideoElement = this.parent.getVideoPlayer(this.proctee.socketid);
  //   if (video) {
  //     const mediaStream = new MediaStream([track]);
  //     try {
  //       //video.autoplay = true;
  //       video.muted = true;
  //       video.srcObject = mediaStream;
  //       video.play();
  //     }
  //     catch (error) {
  //       console.log(error);
  //     }
  //   }
  // }

  toggleAudio() {
    this.audioToggled.emit(this.proctee);

    console.log(`audio toggled for ${this.proctee.username}`);

    if (this.proctee.muted) {
      this.enableAudio();
    }
    else {
      this.disableAudio();
    }
  }

  async enableAudio() {
    //const audio = this.parent.getAudioPlayer(this.proctee.socketid);
    await this.meetingHandlerService.resumeConsumer(this.proctee.socketid, this.audioConsumer, true);
    //audio.play();
    this.proctee.muted = false;
    this.toggleDisplayHighlight();
  }

  async disableAudio() {
    //const audio = this.parent.getAudioPlayer(this.proctee.socketid);
    this.meetingHandlerService.pauseConsumer(this.proctee.socketid, this.audioConsumer, false);
    //audio.pause();
    this.proctee.muted = true;
    this.toggleDisplayHighlight();
  }

  async toggleSpeakingTo() {
    this.speakingToggled.emit(this.proctee);

    this.proctee.speakingTo = !this.proctee.speakingTo;
    this.toggleDisplayHighlight();
    if (this.proctee.speakingTo) {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

      await this.meetingHandlerService.publishToMediasoupRoom(audioStream.getAudioTracks()[0], false, this.proctee.socketid);
      this.enableAudio();      
    }
    else {
      this.meetingHandlerService.localMediasoupProducers.forEach((producer) => {
        //debugger;
        this.meetingHandlerService.closeProducer(producer);
        this.disableAudio();
      });
    }
  }

  flagCandidate() {
    this.flagCandidateClicked.emit(this.proctee);

    this.parent.flagCandidate(this.proctee);

    //this.toastr.warning('Candidate Flagged');
  }

  openChat() {
    this.parent.openChat(this.proctee);
  }

  isScrolledIntoView(elem, scrollElement, offset){
    let $elem = $(elem);
    let $window = $(scrollElement);
    let docViewTop = $window.scrollTop();
    let docViewBottom = docViewTop + $window.height();
    //for it to work this way, this has to be the direct parent element
    let elemTop = elem.offsetTop; //$elem.offset().top;
    let elemBottom = elemTop + $elem.height();
    
    return (((elemBottom+offset) >= docViewBottom) && ((elemTop-offset) <= docViewTop)) || (((elemBottom-offset) <= docViewBottom) && ((elemTop+offset) >= docViewTop));
  }

  scrolledIntoView() {
    let offset = 200;
    if (this.parent.procteescomponents.length > 0) {
      offset = $(this.parent.procteescomponents.first.container.nativeElement).outerHeight() / 2;
    }
    return this.isScrolledIntoView(this.container.nativeElement, this.parent.procteescontainer.nativeElement, offset);
  }

  highlightCandidateBtnClicked() {
    this.proctee.highlighted = !this.proctee.highlighted;
    this.toggleDisplayHighlight();
  }

  toggleDisplayHighlight() {
    this.proctee.displayhighlight = this.proctee.highlighted || !this.proctee.muted || this.proctee.speakingTo || this.proctee.proctorpaused || this.proctee.aiFlags.length > 0;
  }

  async pauseCandidate() {
    this.toggleDisplayHighlight();
    //this.proctee.proctorpaused = !this.proctee.proctorpaused;
    try {
      // if(this.proctee.proctorpaused){
      this.parent.pauseCandidate(this.proctee);
      //   }
      //   else
      //  await this.parent.confirmPauseCandidate(false,this.proctee)
      //this.toastr.warning('Candidate Flagged');
    }
    catch {
      //this.proctee.proctorpaused = !this.proctee.proctorpaused;
    }
  }
  //speakTo() {
  //  this.speakingToggled.emit({ proctee: this.proctee, speakTo: true });
  //}

  //stopSpeakingTo() {
  //  this.speakingToggled.emit({ proctee: this.proctee, speakTo: false });
  //}

  showreason(username) {
    var popup = document.getElementById(username);
    popup.classList.toggle("show");
  }
  
  dismissAiFlag(){
    this.parent.dismissAiFlag(this.proctee);
    this.__proctee.aiFlagsCount = 0;
  }

  async triggerRestartProcteeStreams(){
    this.restartingStream = true;

    try{
      await this.meetingHandlerService.sendCustomPrivateMessage('restartPublish', this.proctee.socketid, {});
    }
    finally{
      setTimeout(() => {
        this.restartingStream = false;
      }, 10000);
    }    
  }

  getTime(time:number){
    return moment(time).fromNow();
  }

}
