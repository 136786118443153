import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProcteeComponent } from './components/proctee/proctee.component';
import { ProcteesComponent } from './components/proctees/proctees.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { OperationsComponent } from './components/operations/operations.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { VideosComponent } from './pages/videos/videos.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ProcteeflagsComponent } from './pages/procteeflags/procteeflags.component';
import { DataTablesModule } from 'angular-datatables';
import { CountdownModule } from 'ngx-countdown';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ExamvideosComponent } from './pages/examvideos/examvideos.component';
import { ExamvideoplayerComponent } from './pages/examvideoplayer/examvideoplayer.component';
import { VjsPlayerComponent } from './components/vjs-player/vjs-player.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VideodownloadComponent } from './components/videodownload/videodownload.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
// import { ProcteesPaginationComponent } from './components/proctees-pagination/proctees-pagination.component';

@NgModule({
  declarations: [
    AppComponent,
    ProcteeComponent,
    ProcteesComponent,
    SettingsComponent,
    PaginatorComponent,
    OperationsComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    VideosComponent,
    ProcteeflagsComponent,
    PaymentComponent,
    ExamvideosComponent,
    ExamvideoplayerComponent,
    VjsPlayerComponent,
    VideodownloadComponent,
    DashboardLayoutComponent,
    // ProcteesPaginationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    DataTablesModule,
    CountdownModule,
    ToastrModule.forRoot(),
    SelectDropDownModule,
    // NgbModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
