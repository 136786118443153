import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {UserService} from './services/user.service';
// import { basename } from 'path';
@Component({
    template: ''
  })
export abstract class LoggedInOnInit implements OnInit {
    _userservice: UserService;
    _router: Router;
    _activatedroute: ActivatedRoute;
    constructor(router: Router, userservice: UserService, activatedroute: ActivatedRoute){
        this._router = router;
        this._userservice = userservice;
        this._activatedroute = activatedroute;
    }
   async ngOnInit(){
        if(!this.loggedIn()){
            this._router.navigate(['login'], { queryParams: {url: window.location.href.replace(document.location.origin, '')} });
            return false;
        }
        else{
            return true;
        }
    }
    loggedIn(){
        return this._userservice.getToken();
    }
}