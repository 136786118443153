<app-dashboard-layout pageTitle="Flagged Candidates">
  <div class="content-wrapper">
    <form
      #login="ngForm"
      name="getflags"
      (ngSubmit)="searchClicked()"
      class="max-w-md"
    >
      <label
        class="rounded-lg flex bg-primary/10 border-primary/20 border-4 text-sm w-full p-4 gap-4 items-center"
      >
        <ngx-select-dropdown
          (change)="selectionChanged($event)"
          [multiple]="false"
          [config]="config"
          [options]="reqExams"
          class="flex-grow"
        ></ngx-select-dropdown>

        <button
          type="submit"
          [disabled]="!filled()"
          class="bg-primary rounded-lg text-white p-2 px-8"
        >
          <div class="text-xl material-icons">search</div>
        </button>
      </label>
    </form>

    <div class="search-result" *ngIf="flags.size > 0">
      <div class="mx-auto col-sm-12 col-sm-offset-1">
        <p class="search-result-count">Showing {{ flags.size }} candidates</p>
        <div class="table-responsive proctor-monitor-table-container">
          <!-- <table class="table table-dark proctor-monitor-table">
              <th>S No.</th><th>Candidate No.</th><th>Link</th>
              <tr *ngFor="let recording of recordings; let i = index"><th scope="row">{{i + 1}}</th><td><b>{{recording.candidateno}}</b></td><td><a href="{{recording.url}}" target="_blank">{{recording.datetimestring}}</a></td></tr>
            </table> -->
          <table
            id="tabledata"
            datatable
            [dtOptions]="dtOptions"
            class="table table-dark proctor-monitor-table"
          >
            <thead>
              <tr>
                <th>S No.</th>
                <th>Candidate No.</th>
                <th colspan="2">Flags</th>
                <th>Recordings</th>
                <th>Review Comments</th>
                <th>Un-Flagged</th>
                <th>Save</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let candidateflags of flags | keyvalue: asIsOrder;
                  let i = index
                "
                [ngClass]="{ even: i % 2 == 0 }"
              >
                <ng-container
                  *ngFor="let flag of candidateflags.value; first as isFirst"
                >
                  <tr *ngIf="isFirst">
                    <td scope="row">
                      <b>{{ i + 1 }}</b>
                    </td>
                    <td>
                      <b>{{ candidateflags.key }}</b>
                    </td>
                    <td>{{ flag.reason }}</td>
                    <td>{{ flag.datetimestring }}</td>
                    <td>
                      <a
                        routerLink="/videos"
                        [queryParams]="{
                          candidateno: candidateflags.key,
                          examid: candidateflags.value[0].examid
                        }"
                        >View Recordings</a
                      >
                    </td>
                    <td>
                      <textarea
                        style="width: 100%; min-width: 200px; padding: 3px"
                        [(ngModel)]="candidateflags.value[0].reviewcomments"
                        [ngModelOptions]="{ standalone: true }"
                      ></textarea
                      ><span style="display: none">{{
                        candidateflags.value[0].reviewcomments
                      }}</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        style="width: 100%"
                        [(ngModel)]="candidateflags.value[0].unflag"
                        [ngModelOptions]="{ standalone: true }"
                      /><span style="display: none">{{
                        candidateflags.value[0].unflag
                      }}</span>
                    </td>
                    <td>
                      <button
                        (click)="
                          updateProcteeFlag(
                            candidateflags.value[0].examid,
                            candidateflags.key,
                            candidateflags.value[0].reviewcomments,
                            candidateflags.value[0].unflag
                          )
                        "
                        class="btn btn-primary"
                        [disabled]="updatingflag"
                      >
                        Save
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="!isFirst">
                    <td scope="row"></td>
                    <td></td>
                    <td>{{ flag.reason }}</td>
                    <td>{{ flag.datetimestring }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </ng-container>
              </ng-container>
              <!-- <tr *ngFor="let candidateflags of flags | keyvalue: asIsOrder; let i = index" [ngClass]="{ even: (i % 2 == 0) }">
                    <td scope="row"><b>{{i + 1}}</b></td>
                    <td><b>{{candidateflags.key}}</b></td>
                    <td style="width: 50%;">
                      <table datatable class="table">
                        <tbody>
                          <tr *ngFor="let flag of candidateflags.value">
                            <td style="width: 70%;">{{flag.reason}}</td><td>{{flag.datetimestring}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td><a routerLink="/videos" [queryParams]="{candidateno: candidateflags.key, examid: candidateflags.value[0].examid}">View Recordings</a></td>
                    <td><textarea style="width: 100%; min-width: 200px; padding:3px" [(ngModel)]="candidateflags.value[0].reviewcomments" [ngModelOptions]="{standalone: true}"></textarea></td>
                    <td><input type="checkbox" style="width: 100%;" [(ngModel)]="candidateflags.value[0].unflag" [ngModelOptions]="{standalone: true}"></td>
                    <td><button (click)="updateProcteeFlag(candidateflags.value[0].examid, candidateflags.key, candidateflags.value[0].reviewcomments, candidateflags.value[0].unflag)" class="btn btn-primary" [disabled]="updatingflag">Save</button></td>
                  </tr> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</app-dashboard-layout>
