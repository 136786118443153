import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  ActivatedRoute,
  DefaultUrlSerializer,
  PRIMARY_OUTLET,
  Router,
  UrlSerializer,
} from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  username: string = '';
  password: string = '';
  //company: string = '';
  isLoading = false;
  url: string = '';
  constructor(
    private userservice: UserService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {}

  async logoutClicked() {
    try {
      this.isLoading = true;
      this.cookieService.delete('authToken');
      this.cookieService.delete('authCompany');
      this.isLoading = false;
      this.router.navigate(['/']);
    } catch (error) {
      //debugger;
      this.isLoading = false;
      alert(error.error.error);
    }
  }
}
