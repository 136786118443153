<div id="procteescomponentcontainer">
  <div id="procteescomponentinnercontainer">
    <app-settings (settingsChanged)="settingsChanged($event)" (accessCodeChanged)="accessCodeEntered($event)" [proctorfullname]="proctorfullname" [candidates]="proctees.size" [requireAttention]="handsRaised" [aiFlags]="aiFlags"></app-settings>
    <!-- <div style="overflow: auto;"> -->
    <div id="procteescontainer" #procteescontainer [ngStyle]="{display: this.connected && proctees.size > 0 ? null : 'none'}" *ngIf="this.connected && proctees.size > 0; else notConnectedOrNoProctees">
      <app-proctee #proctee *ngFor="let proctee of proctees | keyvalue: asIsOrder; trackBy:identifyProctee" 
      [proctee]="proctee.value" 
      (audioToggled)="audioToggled($event)" 
      (speakingToggled)="speakingToggled($event)" 
      (streamSettingRequired)="streamSettingRequired($event)" 
      (flagCandidateClicked)="flagCandidateClicked($event)"
      (highlightCandidateClicked)="highlightCandidateClicked($event)"
      [ngClass]="{'hidden': (settings.filterby === 'highlighted' && !proctee.value.displayhighlight) || 
      (settings.filterby === 'attentionrequested' && !proctee.value.handRaised) || 
      (settings.filterby === 'proctorpaused' && !proctee.value.proctorpaused) ||
      (settings.filterby === 'aiFlagged' && !proctee.value.aiFlags.size > 0)}"
      ></app-proctee>
      <!--<input type="hidden" id="startIndex" value="{{procteeStartIndex}}" />
      <input type="hidden" id="endIndex" value="{{procteeEndIndex}}" />-->
      
    </div>
    <ng-template #notConnectedOrNoProctees>
      <div class="not-connected-or-no-proctees-div">
        <div class="not-connected-div" *ngIf="!this.connected && !this.connecting; else noProcteesOrConnecting">Not Connected</div>
        <ng-template #noProcteesOrConnecting>
          <div class="no-proctees-div" *ngIf="this.connected && !this.connecting; else connectingTemplate">No Candidates Yet</div>
          <ng-template #connectingTemplate>
            <div class="connectingdiv"></div>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>
    <!-- </div> -->
  </div>
  <!-- <div id="pagination">
    <app-proctees-pagination [pages]="pages" [currentPage]="pageNo" (pageNoClicked)="goToPage($event)"></app-proctees-pagination>
  </div> -->
</div>

<div class="modal" tabindex="-1" role="dialog" id="flagModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Specify Reason</h5>
      </div>
      <div class="modal-body">
        <p><textarea #flagreasontextarea [(ngModel)]="flagreason" style="width: 100%; min-height: 200px; padding: 3px"></textarea></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="this.confirmFlagCandidate()" [disabled]="flagging">Submit</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="chatModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="currentchatproctee">
        <h5 class="modal-title">Chat: ({{currentchatproctee.name}})</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="currentchatproctee">
        <div class="chat-modal-chat">
          <div *ngIf="chat[currentchatproctee.username]" class="chat-modal-chat-inner">
            <div *ngFor="let chatline of chat[currentchatproctee.username]" class="chat-modal-chat-line" [ngClass]="{local: chatline.local, remote: !chatline.local}">
              <span class="chat-you-span message-prefix" *ngIf="chatline.local">You: </span><span class="chat-candidate-span message-prefix" *ngIf="!chatline.local">Candidate: </span><span [innerHTML]="chatline.message"></span>
            </div>
          </div>        
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <textarea #chattextarea [(ngModel)]="chatmessage" style="width: 100%; padding: 3px" (keydown.enter)="sendChatMessage(); false"></textarea>
        </div>
        <div class="chat-button-div">
          <button (click)="sendChatMessage()" class="btn btn-primary">Send</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="pauseModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Select/Specify Reason For Pause</h5>
      </div>
      <div class="modal-body">
        <select class="form-control mb-5" [(ngModel)]=preconfiguredreason #preconfiguredreasonselect id="allowEnroll" name="preconfiguredreasonselect" placeholder="Please Select Reason">
        <option style="text-overflow: ellipsis;" *ngFor="let reason of pausereasons" value={{reason}}>{{reason}}
        </option>       
      </select>
        <p><textarea name="pausereasontextarea" #pausereasontextarea [(ngModel)]="pausereason" style="width: 100%; min-height: 200px; padding: 3px" placeholder="Specify reason if reason is not in dropdown" value=""></textarea></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="this.confirmPauseCandidate()" [disabled]="pausing || (!pausereasontextarea.value && !preconfiguredreason)">Submit</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" tabindex="-1" role="dialog" id="proctorname" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Enter Full Name:</h5>
      </div>
      <div class="modal-body">      
        <p>
          <input type="text" name="proctorfullnamebox" #proctorfullnamebox ngModel style="width: 100%; padding: 5px;" (keydown.enter)="setproctorfullname(proctorfullnamebox.value)"  placeholder="Jane Doe" />
        
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="setproctorfullname(proctorfullnamebox.value)"   [disabled]="!proctorfullnamebox.value">Continue</button>
      </div>
    </div>
  </div>
</div>