import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoggedInOnInit } from 'src/app/loggedInInit';
import { RecordingsService } from 'src/app/services/recordings.service';
import { UserService } from 'src/app/services/user.service';


declare var videojs: any;

@Component({
  selector: 'app-examvideoplayer',
  templateUrl: './examvideoplayer.component.html',
  styleUrls: ['./examvideoplayer.component.css']
})
export class ExamvideoplayerComponent extends LoggedInOnInit {
  @ViewChild('v') v;
  checkfileexistinterval: any;
  videosrc: string
  examid: any;
  candidateno: any;
  time: any;
  options: any;
  player: any;
  show: boolean
  loading: boolean;
  displaymsg: any;
  constructor(private recordingservice: RecordingsService, private activatedroute: ActivatedRoute, private router: Router, private userservice: UserService, private toastr: ToastrService) {
    super(router, userservice, activatedroute);
    this.examid = this.activatedroute.snapshot.queryParams.examid ?? '';
    this.candidateno = this.activatedroute.snapshot.queryParams.candidateno ?? '';
    this.time = this.activatedroute.snapshot.queryParams.time ?? '';
  }
  async ngOnInit() {
    const ret = super.ngOnInit();
    if (this.loggedIn()) {
      if (this.examid && this.candidateno) {
        this.displaymsg = "Please Wait Video Is Being Processed";
        this.loading = true
        this.seturl();
      }
    }
    return ret;
  }

  async checkfileexists() {
    let exists = await this.recordingservice.checkmp4exists(this.examid, this.candidateno, this.time).toPromise()
      .catch(
        (error) => {
          this.toastr.error(`${error.status} ${error.statusText}`);
          this.toastr.error(`${error.error}`);
          return;
        });
    if (exists) {
      this.loading = false;
      if (this.checkfileexistinterval) {
        clearInterval(this.checkfileexistinterval);
      }
      this.seturl()
    }
  }

  async seturl() {
    let url = await this.recordingservice.getRecordingPlayerUrl(this.examid, this.candidateno, this.time).toPromise()
      .catch(
        (error) => {
          this.toastr.error(`${error.status} ${error.statusText}`);
          this.toastr.error(`${error.error}`);
          this.loading = false;
          this.router.navigate(['/videos'], { queryParams: { examid: this.examid, candidateno: this.candidateno } });
          return;
        });
    if (url.url) {
      this.loading = false;
      this.videosrc = url.url;
      this.options = `{autoplay: true, controls: true, sources: [{ src: ${this.videosrc}, type: 'video/mp4' }]}`
      console.log(this.videosrc);
    }
    else {
      if (!this.checkfileexistinterval) {
        this.checkfileexistinterval = setInterval(() => {
          this.checkfileexists();
        }, 60000)
      }
    }
  }



  getDuration(v) {
    let dur = v.duration;
    dur = dur.toFixed();
    return dur;
  }

  ngAfterViewInit() {
    var a = document.getElementById("video-player");
    debugger
    this.player = videojs(document.getElementById('video-player'), {
      sources: {
        src: this.videosrc,
        type: "video/mp4"
      },
    }, function onPlayerReady() {
      console.log('onPlayerReady', this);
      this.show = true;
      // Here where the magic happens :D 

      this.on('loadedmetadata', () => {

      });
      this.on('timeupdate', () => {

      });
      this.on('loadeddata', () => {

      });
    })
  }
}
