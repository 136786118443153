import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoggedInOnInit } from 'src/app/loggedInInit';
import { ProcteeService } from 'src/app/services/proctee.service';
import { UserService } from 'src/app/services/user.service';
import TableToExcel from "@linways/table-to-excel";

@Component({
  selector: 'app-procteeflags',
  templateUrl: './procteeflags.component.html',
  styleUrls: ['./procteeflags.component.css']
})
export class ProcteeflagsComponent extends LoggedInOnInit {  
  dtOptions: any = {};
  examid: string = '';
  reqExams: any[] = [];
  flags: Map<string, any[]> = new Map<string, any[]>();
  updatingflag = false;
  config: any = {};
  @Input() proctorfullname;
  constructor(private procteeservice: ProcteeService, private router: Router, private activatedroute: ActivatedRoute, private userservice: UserService, private toastr: ToastrService) { 
    super(router, userservice, activatedroute);
  }

  ngOnInit() {
    const ret = super.ngOnInit();
    if(this.loggedIn()){
      this.examid = this.activatedroute.snapshot.queryParams.examid;
      if(this.examid){
        this.searchClicked();
      }
      this.getExams();

      this.config = { 
        //displayFn:(item: any) => { return `${item.examName} ${item.session}`; }, 
        displayKey:'examName', 
        search:'true', 
        searchOnKey: 'examName', 
        limitTo: 10, 
        noResultsFound: 'No results found!', 
        searchPlaceholder: 'Enter Exam Name', 
        placeholder:'Select Examination' 
      };
    }    
    return ret;
  }

  async getExams(){
    let res: any[] = await this.procteeservice.getReqExams();
    let unique = new Set(this.reqExams);
    res.forEach(r => {
        if(!unique.has(r.examID)){
          unique.add(r.examID);
          this.reqExams = [...this.reqExams, r];;
        }
      });
  }

  async searchClicked(){
    const examid = this.examid;
    const data: any[] = await this.procteeservice.getFlags(this.examid);
    this.flags = new Map<string, any[]>();
    for (const temp of data){
      if(!this.flags.has(temp.candidateno)){
        this.flags.set(temp.candidateno, []);
      }
      //this.flags.size
      const candidaterecordingsurl = `/videos?candidateno=${temp.candidateno}&examid=${examid}`;
      this.flags.get(temp.candidateno).push({ examid: examid, candidateno: temp.candidateno, reason: temp.reason, reviewcomments: temp.reviewcomments, unflag: temp.unflag, datetimestring: new Date(parseInt(temp.time)).toString(), candidaterecordingsurl: candidaterecordingsurl});
    }
    this.router.navigate(['flags'], { queryParams: { examid: examid } });

    this.dtOptions = {
      //ajax: data,      
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        {
          text: 'Export To Excel',
          action:function (e, dt, node, config) {
            TableToExcel.convert(document.getElementById("tabledata"));
          }
        }
      ]
    };
  }

  selectionChanged(event){
    this.examid = event.value.examID;
  }

  filled(){
    return this.examid;
  }

  asIsOrder(a, b) {
    return 1;
  }

  async updateProcteeFlag(examid: string, candidateno: string, reviewcomments: string, unflag: boolean){
    try{
      this.updatingflag = true;
      let res = await this.procteeservice.updateProcteeFlag(examid, candidateno, reviewcomments, unflag,this.proctorfullname);
      if(res["Status"] === "SUCCESS"){
        this.toastr.success('Saved');
      }
      else {
        console.error(res);
        this.toastr.warning("Unable to update flag on third party system");
      }
    }
    catch(error){
      console.error(error);
      this.toastr.error('Unable to save');
    }
    finally{
      this.updatingflag = false;
    }

  }
}
