<app-dashboard-layout pageTitle="Video Player">
  <div class="content-wrapper">
    <div class="search-result" *ngIf="loading">
      <div
        class="mx-auto mt-5 col-sm-10 col-sm-offset-1"
        style="text-align: center"
      >
        <span class="mx-2">
          <img
            height="70vh"
            *ngIf="loading"
            src="../../../assets/images/loading.gif"
          />
        </span>
        <h5>{{ displaymsg }}</h5>
      </div>
    </div>

    <video
      #v
      controls
      autoplay
      *ngIf="videosrc"
      class="rounded max-w-screen-lg border-4 border-primary/20 w-full aspect-video"
    >
      <source src="{{ videosrc }}" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</app-dashboard-layout>
