import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  req_headers;
 
  constructor(private http: HttpClient, private userservice: UserService, private toastr: ToastrService) { 
    this.req_headers = new HttpHeaders({ 
      "content-type": "application/json", 
      "accept": "application/json", 
      "Authorization": `Bearer ${this.userservice.getToken()}`
    });
  }

  initiatePayment(units: number): Promise<any>{
    return this.http.post(environment.serviceurl + `/payment/initiatepayment`, { units: units }, { headers: this.req_headers }).toPromise()
    .catch((error)=>{
      this.toastr.error(`${error.status} ${error.statusText}`);
    });
  }
  
  getUnitPrice():any{
    return this.http.get(`${environment.serviceurl}/payment/getunitprice`,{ headers: this.req_headers }).toPromise()
    .catch((error)=>{
      this.toastr.error(`${error.status} ${error.statusText}`);
    });
  }  
}
