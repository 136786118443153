import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  req_headers;
  // username: string = '';
  // password: string = '';
  // company: string = '';

  constructor(private http: HttpClient, private cookieService: CookieService, private toastr: ToastrService) { 
    this.req_headers = new HttpHeaders({ "content-type": "application/json", "accept": "application/json" });
  }

  authenticate(username: string, password: string):Promise<any>{
    let data = {
      "username": username,
      "password": password
    };

    return this.http.post(environment.serviceurl + '/users/authenticate', data, { headers: this.req_headers }).toPromise()
    .catch((error)=>{
      this.toastr.error(`${error.status} ${error.statusText}`);
    });
  }

  getToken(){
    return this.cookieService.get('authToken');
  }

  getCompany(){
    return this.cookieService.get('authCompany');
  }

  sleep(milliseconds: number){
    return new Promise((resolve, reject) =>{
      setTimeout(resolve, milliseconds);
    });
  }
}
