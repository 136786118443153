import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
@Component({
  selector: 'app-vjs-player',
  template: `
    <video #target class="video-js" controls muted playsinline preload="none"></video>
  `,
  styleUrls: [
    './vjs-player.component.css'
  ],
  encapsulation: ViewEncapsulation.None,
})

export class VjsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;

  // See options: https://videojs.com/guides/options
  fluid: boolean = true;
  @Input() src: string

  aspectRatio: string
  autoplay: boolean = true;
  type: string = "video/mp4";

  options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    },
  };
  player: videojs.Player;

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
      
  }
  // Instantiate a Video.js player OnInit
  ngAfterViewInit () {
    this.options = {
      fluid: this.fluid,
      aspectRatio: this.aspectRatio,
      autoplay: this.autoplay,
      sources: {
        src: this.src,
        type: this.type,
      }
    };
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      console.log('onPlayerReady', this);
    });
  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}