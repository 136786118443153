import { Component, OnInit, EventEmitter, Output, Input, Host } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MeetingHandlerService } from 'src/app/services/meeting-handler.service';
import { Settings } from '../../models/Settings';
import { ProcteesComponent } from '../proctees/proctees.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  settings: Settings = new Settings();/* {
    procteesperpage: 3,//currently not used
    autoswitchpage: true,
    secondsperpage: 10
  };*/
  accesscode: string;
  @Output() settingsChanged: EventEmitter<Settings> = new EventEmitter<Settings>();
  @Output() accessCodeChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() candidates: number;
  @Input() requireAttention: number;
  @Input() proctorfullname:string;
  @Input() aiFlags: number;
  
  constructor(private activatedroute: ActivatedRoute, private meetingHandlerService: MeetingHandlerService, @Host() public procteesComponent: ProcteesComponent) {
    this.accesscode = this.activatedroute.snapshot.queryParams.accesscode;
  }

  ngOnInit(): void {
    this.setCssVariables();
  }

  setCssVariables() {
    switch (this.settings.procteesperpage.toString()) {
      case '1':
        document.documentElement.style.setProperty("--proctee-width", "100%");
        document.documentElement.style.setProperty("--proctee-height", "100%");
        break;
      case '3':
        document.documentElement.style.setProperty("--proctee-width", "calc(100% / 3)");
        document.documentElement.style.setProperty("--proctee-height", "100%");
        break;
      case '6':
        document.documentElement.style.setProperty("--proctee-width", "calc(100% / 3)");
        document.documentElement.style.setProperty("--proctee-height", "50%");
        break;
      case '9':
        document.documentElement.style.setProperty("--proctee-width", "calc(100% / 3)");
        document.documentElement.style.setProperty("--proctee-height", "calc(100% / 3)");
        break;
      case '12':
        document.documentElement.style.setProperty("--proctee-width", "calc(100% / 4)");
        document.documentElement.style.setProperty("--proctee-height", "calc(100% / 3)");
        break;
      default:
        this.settings.procteesperpage = 12;
        this.setCssVariables();
    }
    
    //root.style.setProperty('--proctees-template-rows', '50% 50%');
  }

  secondsPerPageChanged(): void {
    this.settingsChanged.emit(this.settings);
  }

  numProcteesChanged(): void {
    //console.log('Proctees per page changed to: ' + this.settings.procteesperpage);
    this.setCssVariables();
    this.settingsChanged.emit(this.settings);
  }

  autoswitchChanged(): void {
    //console.log('autoswitch changed to ' + this.settings.autoswitchpage);
    this.settingsChanged.emit(this.settings);
  }

  connectClicked(): void {
    //console.log('here');
    this.accessCodeChanged.emit({ accesscode:this.accesscode,fullname:this.proctorfullname});
  }

  disconnectClicked(){
    this.meetingHandlerService.leaving = true;
    this.meetingHandlerService.disconnect();
  }

  filterByChanged():void{
    this.settingsChanged.emit(this.settings);
  }
}
