<app-dashboard-layout pageTitle="Download Recordings">
  <div class="content-wrapper">
    <form
      #login="ngForm"
      name="getrecordings"
      (ngSubmit)="searchClicked()"
      class="rounded-lg flex max-w-md bg-primary/10 border-4 border-primary/20 text-sm mb-8 w-full items-center overflow-hidden"
    >
      <div class="text-primary ml-4 text-3xl material-icons">search</div>

      <input
        required
        type="search"
        name="examid"
        [(ngModel)]="examid"
        placeholder="Examination ID"
        (change)="onChangeEvent($event)"
        class="bg-transparent flex-grow text-sm w-full p-4 focus:outline-0"
      />
    </form>

    <img
      height="20vh"
      *ngIf="loading"
      class="mx-auto max-w-sm"
      src="../../../assets/images/loading.gif"
    />

    <div class="search-result" *ngIf="searchcomplete">
      <div class="mx-auto col-sm-10 col-sm-offset-1">
        <!-- <p class="search-result-count">Showing {{recordings.length}} entries</p> -->
        <div class="">
          <table class="table table-dark proctor-monitor-table">
            <thead>
              <tr>
                <th>Exam ID</th>
                <th>Download Link</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">{{ examid }}</th>
                <td>
                  <b>
                    <button
                      [disabled]="processing"
                      style="color: #78b5f7; border: 0; cursor: pointer"
                      (click)="openconfirmmodal()"
                      target="_blank"
                    >
                      {{ examid }}_link
                    </button>
                    <span class="mx-2">
                      <img
                        height="15vh"
                        *ngIf="processing"
                        src="../../../assets/images/loading.gif"
                      />
                    </span>
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="m-2 p-2" *ngIf="message">{{ message }}</div>
      </div>
    </div>
    <a href="{{ presignedurl }}" target="_blank" id="click_me" hidden></a>
  </div>

  <div
    class="modal fade"
    id="confirmdownload"
    data-keyboard="false"
    data-backdrop="static"
    aria-hidden="true"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="m-2 modal-title">ZIP Download Confirmation</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-0 modal-body">
          <p class="text-center pb-2">Download might take a while</p>
          <p class="text-center pb-2">
            Are you sure you want to download all candidates' recordings for the
            exam?
          </p>
          <input
            style="width: 100%"
            *ngIf="!processed"
            class="m-2 form-control"
            placeholder="Enter Email To Send Download Link"
            required
            type="email"
            id="emailto"
            name="to"
            [(ngModel)]="to"
          />
          <div class="flex-wrap gap-1.5 d-flex justify-content-center">
            <button
              type="submit"
              class="mx-2 btn btn-primary"
              (click)="initiatedownload()"
            >
              Download
            </button>
            <button
              type="button"
              class="mx-2 btn btn-danger"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-dashboard-layout>
